import { React, useEffect, useState } from "react";
import { useMyData } from './MyProvider';
import { useNavigate } from "react-router-dom";

export default function InfoGroupConfirm() {
  const { dataMy, setSignupData, getSignupData } = useMyData();
  const [signupData, signupMode] = getSignupData();


  // 確認画面へ
  const submit = (e) => {
    e.preventDefault();

    if(e.target.id==='back') {
      setSignupData( {
        ...signupData
      },"back" );
    }

    if(e.target.id==='next') {
      setSignupData( {
        ...signupData
      },"send" );
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  }


  return (
    <>

      <form>

      <table className="Form" summary="#">
      <tbody>
      <tr>
          <th>団体名</th>
          <td>{dataMy.r_name}</td>
        </tr>
        <tr>
          <th>団体名フリガナ</th>
          <td>{dataMy.r_kana}</td>
        </tr>
        <tr>
          <th>代表者名 <span>※必須</span></th>
          <td>{signupData.r_d_l_name} {signupData.r_d_f_name}</td>
        </tr>
        <tr>
          <th>代表者名フリガナ <span>※必須</span></th>
          <td>{signupData.r_d_l_kana} {signupData.r_d_f_kana}</td>
        </tr>
        <tr>
          <th>ご住所 <span>※必須</span></th>
          <td>{signupData.r_zipcode}<br />
            {signupData.r_address}
          </td>
        </tr>
        <tr>
          <th>電話番号1 <span>※必須</span></th>
          <td>{signupData.r_tel1a}ー{signupData.r_tel1b}ー{signupData.r_tel1c}</td>
        </tr>
        <tr>
          <th>電話番号2</th>
          <td>{signupData.r_tel2a && <>{signupData.r_tel2a}ー{signupData.r_tel2b}ー{signupData.r_tel2c}</>}</td>
        </tr>
      </tbody>
      </table>

      <h6>担当者情報</h6>

      <table className="Form" summary="#">
      <tbody>
        <tr>
          <th>担当者名</th>
          <td>{signupData.j_d_l_name} {signupData.j_d_f_name}</td>
        </tr>
        <tr>
          <th>担当者名フリガナ</th>
          <td>{signupData.j_d_l_kana} {signupData.j_d_f_kana}</td>
        </tr>
        <tr>
          <th>ご住所</th>
          <td>{signupData.j_zipcode && <>{signupData.j_zipcode}<br /></>}
            {signupData.j_address}
          </td>
        </tr>
        <tr>
          <th>電話番号</th>
          <td>{signupData.j_tel1a && <>{signupData.j_tel1a}ー{signupData.j_tel1b}ー{signupData.j_tel1c}</>}</td>
        </tr>
      </tbody>
      </table>

      <div className="Form__btnbox">
        <div className="Form__btn-return">
          <input value="戻る" type="submit" id="back" onClick={submit} />
        </div>
        <div className="Form__btn-confirm">
          <input value="登録する" type="submit" id="next" onClick={submit} />
        </div>
      </div>
    </form>
  </>
 );
}
