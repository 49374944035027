import { useLocation } from "react-router-dom";

export default function NotFound() {
  let location = useLocation();
  return (
    <div>
      <h1>404 Not Found.</h1>
      <p>
      Resource not found at { location.pathname }
      </p>
    </div>
  );
}