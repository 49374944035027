import { React, useEffect, useState } from "react";
import { useMyData } from './MyProvider';
import { useInput, useCheckbox } from "./hooks";
import { useInView } from 'react-intersection-observer';

export default function SignUpGroupConfirm() {
  const { setSignupData, getSignupData } = useMyData();
  const [signupData, signupMode] = getSignupData();


  // 確認画面へ
  const submit = (e) => {
    e.preventDefault();

    if(e.target.id==='back') {
      setSignupData( {
        ...signupData
      },"back" );
    }

    if(e.target.id==='next') {
      setSignupData( {
        ...signupData
      },"send" );
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  }


  return (
    <>

      <h1 className="pagetopLabel">利用者登録（団体）</h1>

      <ul className="Progressbar">
        <li><span>情報の入力</span></li>
        <li className="active"><span>入力内容の確認</span></li>
        <li><span>送信・メールの確認</span></li>
        <li><span>申込み完了</span></li>
      </ul>

      <div className="txt">
        <p>入力内容をご確認いただき、「申込み」ボタンを押してください。ご登録のメールアドレスに認証用のメールを送信いたします。
        </p>
      </div>

      <form>

      <table className="Form" summary="#">
      <tbody><tr>
          <th>団体名 <span>※必須</span></th>
          <td>{signupData.r_name}</td>
        </tr>
        <tr>
          <th>団体名フリガナ <span>※必須</span></th>
          <td>{signupData.r_kana}</td>
        </tr>
        <tr>
          <th>代表者名 <span>※必須</span></th>
          <td>{signupData.r_d_l_name} {signupData.r_d_f_name}</td>
        </tr>
        <tr>
          <th>代表者名フリガナ <span>※必須</span></th>
          <td>{signupData.r_d_l_kana} {signupData.r_d_f_kana}</td>
        </tr>
        <tr>
          <th>メールアドレス（ID）<span>※必須</span></th>
          <td>{signupData.email}</td>
        </tr>
        <tr>
          <th>パスワード <span>※必須</span></th>
          <td>{signupData.password.replace(/./g,'.')}</td>
        </tr>
        <tr>
          <th>ご住所 <span>※必須</span></th>
          <td>{signupData.r_zipcode}<br />
            {signupData.r_address}
          </td>
        </tr>
        <tr>
          <th>電話番号1 <span>※必須</span></th>
          <td>{signupData.r_tel1a}ー{signupData.r_tel1b}ー{signupData.r_tel1c}</td>
        </tr>
        <tr>
          <th>電話番号2</th>
          <td>{signupData.r_tel2a && <>{signupData.r_tel2a}ー{signupData.r_tel2b}ー{signupData.r_tel2c}</>}</td>
        </tr>
      </tbody>
      </table>

      <h6>担当者情報</h6>

      <table className="Form" summary="#">
      <tbody>
        <tr>
          <th>担当者名</th>
          <td>{signupData.j_d_l_name} {signupData.j_d_f_name}</td>
        </tr>
        <tr>
          <th>担当者名フリガナ</th>
          <td>{signupData.j_d_l_kana} {signupData.j_d_f_kana}</td>
        </tr>
        <tr>
          <th>ご住所</th>
          <td>{signupData.j_zipcode && <>{signupData.j_zipcode}<br /></>}
            {signupData.j_address}
          </td>
        </tr>
        <tr>
          <th>電話番号</th>
          <td>{signupData.j_tel1a && <>{signupData.j_tel1a}ー{signupData.j_tel1b}ー{signupData.j_tel1c}</>}</td>
        </tr>
      </tbody>
      </table>

      <div className="Form__btnbox">
        <div className="Form__btn-return">
          <input value="戻る" type="submit" id="back" onClick={submit} />
        </div>
        <div className="Form__btn-confirm">
          <input value="申込み" type="submit" id="next" onClick={submit} />
        </div>
      </div>
    </form>
  </>
 );
}
