import { React, useState, useEffect } from "react";
import { Outlet, useLocation,Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";


export default function Forgot() {
  const { getSignupData, forgot } = useMyData();
  const [signupData, signupMode] = getSignupData();

  const [emailProps, resetEmail] = useInput(""); // Email(ログインID)(利用者メールアドレス)

  const [hasError, setHasError] = useState(true); // エラーチェック
  const [sendStatus, setSendStatus] = useState(""); // 戻り待ち

  // エラーチェック
  useEffect(() => {
    let err = false;

    if(emailProps.value==="") err=true;

    setHasError(err);
  });


  // 確認画面へ
  const submit = (e) => {
    e.preventDefault();
    setSendStatus("WAITING");
    forgot(emailProps.value, setSendStatus);
  }

  return (
    <>
      <div className="Mypagelogin">
      <h1 className="pagetopLabel">パスワード再設定</h1>
      <p>ご登録のメールアドレス（ID）を入力のうえ、メール送信ボタンを押してください。<br />パスワード再設定用メールを送信します。確認のうえ、パスワードの再設定を行ってください。</p>
      <form onSubmit={submit}>
        <table className="Form" summary="#">
        <tbody>
          <tr>
            <th>ID (メールアドレス)</th>
            <td><input type="text" {...emailProps} required autoComplete="email" className="w_60" /></td>
          </tr>
        </tbody>
        </table>
        { sendStatus=='WAITING' && <>送信中</>}
        { sendStatus=='OK' && <><span>送信しました</span></>}
        { sendStatus=='NG' && <><span className="notice">送信失敗しました</span></>}


        <div className="Form__btnbox">
          <div className="Form__btn-confirm">
            <input value="メール送信" type="submit" id="next" disabled={ hasError || sendStatus=='WAITING' } />
          </div>
        </div>

      </form>
      </div>
    </>
 );
}
