import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMyData } from './MyProvider';

export default function InfoGroupSend() {
  const { setSignupData, getSignupData, updateInfo } = useMyData();
  const [signupData, signupMode] = getSignupData();

  const [sendCheck, setSendCheck] = useState(false); // 送信中
  const [signupId, setSignupId] = useState(""); // signupId


  useEffect(() => {
    updateInfo(setSendCheck, 1); // 団体
  },[]);


  return (
    <>

      <div className="txt">

      { ! sendCheck && <h3>送信中………</h3> }

      { sendCheck && <h3>利用者情報を変更しました。</h3> }

      </div>
      <div className="Form__btnbox">
        <div className="Form__btn-return">
          <Link to='/info' title="https://mypage.yokohama-minatomiraihall.jp/info">戻る</Link>
        </div>
      </div>


    </>
  );
}
