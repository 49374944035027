import { React, useEffect, useState } from "react";
import { useMyData } from './MyProvider';
import { useNavigate } from "react-router-dom";

export default function InfoIndivisualConfirm() {
  const { dataMy, setSignupData, getSignupData } = useMyData();
  const [signupData, signupMode] = getSignupData();


  // 確認画面へ
  const submit = (e) => {
    e.preventDefault();

    if (e.target.id === 'back') {
      setSignupData({
        ...signupData
      }, "back");
    }

    if (e.target.id === 'next') {
      setSignupData({
        ...signupData
      }, "send");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  }


  return (
    <>

      <form>

        <table className="Form" summary="#">
          <tbody>
            <tr>
              <th>お名前</th>
              <td>{dataMy.r_d_l_name} {dataMy.r_d_f_name}</td>
            </tr>
            <tr>
              <th>フリガナ</th>
              <td>{dataMy.r_d_l_kana} {dataMy.r_d_f_kana}</td>
            </tr>
            <tr>
              <th>ご住所 <span>※必須</span></th>
              <td>{signupData.r_zipcode}<br />
                {signupData.r_address}
              </td>
            </tr>
            <tr>
              <th>電話番号1 <span>※必須</span></th>
              <td>{signupData.r_tel1a}ー{signupData.r_tel1b}ー{signupData.r_tel1c}</td>
            </tr>
            <tr>
              <th>電話番号2</th>
              <td>{signupData.r_tel2a && <>{signupData.r_tel2a}ー{signupData.r_tel2b}ー{signupData.r_tel2c}</>}</td>
            </tr>
          </tbody>
        </table>

        <div className="Form__btnbox">
          <div className="Form__btn-return">
            <input value="戻る" type="submit" id="back" onClick={submit} />
          </div>
          <div className="Form__btn-confirm">
            <input value="登録する" type="submit" id="next" onClick={submit} />
          </div>
        </div>
      </form>
    </>
  );
}
