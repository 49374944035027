import { React } from "react";
import ReserveAddSpCalWeek from "./ReserveAddSpCalWeek";


export default function ReserveAddSpCal(props) {

  let theDay = new Date(props.month);
  let d = (theDay.getDay() + 6) % 7;
  let m = theDay.getMonth();

  let count=1;
  let date = new Array();
  for (let i = 0; i < d ; i++ ) {
    date.push({key: count});
    count++;
  }
  while ( m == theDay.getMonth() ) {
    date.push({
      date: new Date(theDay) ,
      key: count ,
    });
    theDay.setDate(theDay.getDate() + 1);
    count++;
  }
  d = 7 - (theDay.getDay() + 6) % 7;
  if(d < 7) {
    for (let i = 0; i < d ; i++ ) {
      date.push({key: count});
      count++;
    }
  }
  
  const sliceByNumber = (array, number) => {
    const length = Math.ceil(array.length / number)
    return new Array(length).fill().map((_, i) =>
      array.slice(i * number, (i + 1) * number)
    )
  }

  const weeks = sliceByNumber(date,7);

  return (
    <>
          <table className="CalendarSmall">
            <thead>
              <tr>
                <th>月</th>
                <th>火</th>
                <th>水</th>
                <th>木</th>
                <th>金</th>
                <th className="saturday">土</th>
                <th className="sunday">日</th>
              </tr>
            </thead>
            <tbody>
              {weeks.map((week) => (
                <ReserveAddSpCalWeek key={week[0].key} week={week} dataPractice={props.dataPractice} enable={props.enable} />
              ))}
            </tbody>
          </table>
    </>
  );

}
