import { React, useEffect, useState } from "react";
import { Outlet, useLocation,Link } from "react-router-dom";
import { useMyData } from './MyProvider';

export default function SignUp() {
  let location = useLocation();
  const { setSignupData, getSignupData } = useMyData();
  const [signupData, signupMode] = getSignupData();

  useEffect(() => {
    if (location.pathname === '/signup') {
        setSignupData( {
        },"" );
    }
  },[location.pathname]);

  return (
    <>
      {location.pathname === '/signup' && <>

        <div className="Signupselect">
          <h1 className="pagetopLabel">利用者登録</h1>
          <p>個人での申込み／団体での申込み、どちらかをお選びください。</p>
          <div className="l-row-center">
            <div className="l-halfColumn Mypagebtn">
              <Link to="indivisual">
                <div><img src="/images/system/s-individual.svg" alt="" className="signup" /></div>
                <p>個人での申込み</p>
              </Link>
            </div>
            <div className="l-halfColumn Mypagebtn">
              <Link to="group">
                <div><img src="/images/system/s-group.svg" alt="" className="signup" /></div>
                <p>団体での申込み</p>
              </Link>
            </div>
          </div>
        </div>

      </>}
      <Outlet />
    </>
 );
}
