import { React, useState, useEffect } from "react";
import { Outlet, useLocation, Link, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import NewsItem from "./NewsItem";


export default function NewsList() {
  const { news, getNews } = useMyData();

  const params = useParams();
  const page = params.page || 1;


  const pp = 20;
  //const [page, setPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [pageNews, setPageNews] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    getNews();
  }, []);

  useEffect(() => {
    if(news.length > 0) {
      const thisNews = news.slice((pp * (page - 1)), (pp * page));
      setPageNews([...thisNews])
      let pagesCalc = [];
      let i=1;
      while(1){
        pagesCalc.push(i);
        if(i * pp >= news.length) {
          break;
        }
        i++;
      }
      setPages(pagesCalc);
    }
  }, [news,page]);

  const scrollTop = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      <h1 className="pagetopLabel">ホールからのお知らせ</h1>
      {pageNews.length > 0 &&
      <div className="Mypagenews">
        <ul className="Mypagenews__list">
          {pageNews.map((item) => (
            <li key={item.id}>
              <NewsItem item={item} />
            </li>
          ))}
        </ul>

        <ul className="pager">
          {page > 1 &&
            <li className="prev">
              <Link to={'/news/' + (page - 1)} onClick={scrollTop}>««</Link>
            </li>
          }
          {pages.map((item) => (
            <li key={item}>
              { (item == page) ?
              <span>{item}</span>
              :
              <Link to={'/news/' + item} onClick={scrollTop}>{item}</Link>
              }
            </li>
          ))}
          {[...pages].reverse()[0] != page &&
            <li className="next">
              <Link to={'/news/' + (1 + parseInt(page))} onClick={scrollTop}>»»</Link>
            </li>
          }
        </ul>

        <div className="Form__btnbox">
          <div className="Form__btn-return">
            <Link to='/' title="https://mypage.yokohama-minatomiraihall.jp/">戻る</Link>
          </div>
        </div>

      </div>
      }
    </>
  );
}
