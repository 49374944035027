import { React, useState, useEffect } from "react";
import { Outlet, useParams,Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import ConcertPrAddInput from "./ConcertPrAddInput";
import ConcertPrAddConfirm from "./ConcertPrAddConfirm";
import ConcertPrAddSend from "./ConcertPrAddSend";


export default function ConcertPrAdd() {
  let {id} = useParams();

  const { dataConcertPr } = useMyData();

  return (
    <>
      {dataConcertPr.mode==='confirm' && <ConcertPrAddConfirm />}
      { (dataConcertPr.mode==='send' || dataConcertPr.mode==='sended' ) && <ConcertPrAddSend />}
      { (typeof dataConcertPr === 'undefined' || typeof dataConcertPr.mode === 'undefined' || dataConcertPr.mode == 'back' ) && <ConcertPrAddInput />}

    </>
 );

}
