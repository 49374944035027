import { React, useEffect, useState } from "react";
import { useMyData } from './MyProvider';
import { useNavigate,Link } from "react-router-dom";

export default function InfoIndivisual() {
  const { dataMy, getSignupData } = useMyData();
  const navigate = useNavigate();


  const back = (e) => {
    e.preventDefault();
    navigate('/');
  }


  // 確認画面へ
  const submit = (e) => {
    e.preventDefault();
    navigate('edit');

  }


  return (
    <>

      <form>

      <table className="Form" summary="#">
      <tbody>
        <tr>
          <th>お名前</th>
          <td>{dataMy.r_d_l_name} {dataMy.r_d_f_name}</td>
        </tr>
        <tr>
          <th>フリガナ</th>
          <td>{dataMy.r_d_l_kana} {dataMy.r_d_f_kana}</td>
        </tr>
        <tr>
          <th>メールアドレス（ID）</th>
          <td>{dataMy.email}</td>
        </tr>
        <tr>
          <th>ご住所</th>
          <td>{dataMy.r_zipcode}<br />
            {dataMy.r_address}
          </td>
        </tr>
        <tr>
          <th>電話番号1</th>
          <td>{dataMy.r_tel1}</td>
        </tr>
        <tr>
          <th>電話番号2</th>
          <td>{dataMy.r_tel2}</td>
        </tr>
      </tbody>
      </table>
      <div className="resetpw"><span className="linkArrow">
          <Link to='/forgot' title="https://mypage.yokohama-minatomiraihall.jp/forgot">パスワードの変更（再設定）はこちら</Link>
      </span></div>

      <div className="Form__btnbox">
        <div className="Form__btn-return">
          <input value="戻る" type="submit" id="back" onClick={back} />
        </div>
        <div className="Form__btn-confirm">
          <input value="変更する" type="submit" id="next" onClick={submit} />
        </div>
      </div>
    </form>
  </>
 );
}
