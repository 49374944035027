import { React, useEffect,useState } from "react";
import { Link } from "react-router-dom";

export default function ReserveAddBan() {


  return (
    <>
        <h1 className="pagetopLabel">音楽練習室利用予約</h1>
        <div className="txt">
           <p>お客様のご利用が下記のいずれかに該当したため、音楽練習室利用予約の受付を停止させていただきます。<br /><br />
           ①音楽練習室のキャンセル回数が、一定回数を超えた<br />
           ②予約した音楽練習室を利用しなかった回数が、一定回数を超えた<br />
           ③ホールの管理・運営上、必要と認められた<br /><br />
           恐れ入りますが、横浜みなとみらいホール 運営チーム（045-682-2020）までお電話にてお問合せください。</p>
        </div>
        {false &&
        <div className="txt">
           <p>お客様のご利用が下記のいずれかに該当したため、音楽練習室利用予約の受付を停止させていただきます。<br /><br />
           ①音楽練習室のキャンセル回数が、一定回数を超えた<br />
           ②予約した音楽練習室を利用しなかった回数が、一定回数を超えた<br />
           ③ホールの管理・運営上、必要と認められた<br /><br />
           恐れ入りますが、横浜みなとみらいホール 運営チーム（045-682-2020）までお電話にてお問合せください。</p>
        </div>
        }
        <div className="Form__btnbox"><div className="Form__btn-return"><Link to="/">マイページTOPへ</Link></div></div>
    </>
  );

}
