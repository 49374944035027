import { React } from "react";
import { useMyData } from './MyProvider';
import SignUpIndivisualConfirm from "./SignUpIndivisualConfirm";
import SignUpIndivisualInput from "./SignUpIndivisualInput";
import SignUpIndivisualSend from "./SignUpIndivisualSend";

export default function SignUpIndivisual() {

  const { getSignupData } = useMyData();

  const [signupData, signupMode] = getSignupData();

  console.log(signupMode);

  return (
    <>
      {signupMode==='confirm' && <SignUpIndivisualConfirm />}
      {signupMode==='send' && <SignUpIndivisualSend />}
      {(!signupMode || signupMode==='' || signupMode==='back') && <SignUpIndivisualInput />}

    </>
 );
}