import { React } from "react";


export default function ReserveAddPcCalDate(props) {

  const wday = ['日','月','火','水','木','金','土'];

  return (
    <>
      {props.day.date ? 
      <th colSpan={3}
      className={props.holiday ? "sunday" : props.day.date.getDay()==0 ? "sunday" : props.day.date.getDay()==6 ? "saturday" : ""}
      >{props.day.date.getMonth() + 1}/{props.day.date.getDate()}（{wday[props.day.date.getDay()] + (props.holiday ? '・祝':'')}）</th>
      :
      <th colSpan={3} className="spnone"></th>
      }
    </>
  );

}
