import { React, useEffect,useState } from "react";
import { Link } from "react-router-dom";

export default function ReserveAddAlert() {
  

  return (
    <>
      <div className="Emgbox">
        <p>お客様のご利用が下記のいずれかに該当しています。<br /><br />
          ①音楽練習室のキャンセル回数が多くなっている<br />
          ②予約した音楽練習室を利用しなかった<br /><br />
          今後も同様のキャンセル等が続く場合は、ウェブからの音楽練習室利用予約の受付を停止させていただきます。ご注意ください。</p>
      </div>
      {false && 
      <div className="Emgbox">
        <p>お客様のご利用が下記のいずれかに該当しています。<br /><br />
          ①音楽練習室のキャンセル回数が多くなっている<br />
          ②予約した音楽練習室を利用しなかった<br /><br />
          今後も同様のキャンセル等が続く場合は、ウェブからの音楽練習室利用予約の受付を停止させていただきます。ご注意ください。</p>
      </div>
      }
    </>
  );

}
