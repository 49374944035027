import { React, useEffect, useState } from "react";
import { useMyData } from './MyProvider';

export default function SignUpIndivisualSend() {
  const { setSignupData, getSignupData } = useMyData();
  const [signupData, signupMode] = getSignupData();

  const [sendCheck, setSendCheck] = useState(false); // 送信中
  const [signupId, setSignupId] = useState(""); // signupId

  // 確認画面へ
  const submit = (e) => {
    e.preventDefault();

    setSignupData( {
      ...signupData
    },"back" );
  }

  useEffect(() => {
    getSignupId(setSignupId);
  },[]);

  async function getSignupId(target) {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'getsignupid'
      );
      const data = await response.json();
      if(data && data.id) {
        target(data.id);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if(signupId !== "") {
      signup(setSendCheck);
    }
  },[signupId]);

  async function signup(target) {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'signup',
        {
          headers: {
            'Content-type':'application/json'
          },
          method: "POST",
          body: JSON.stringify({...signupData, signupId, type:0 }),  // type:0 個人
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if(data && data.status) {
        console.log(data);
        target(true);

        setSignupData( {
        },"send" );

      }
    } catch (error) {
      console.log(error);
    }
  }



  return (
    <>

      <h1 className="pagetopLabel">利用者登録（個人）</h1>

      <ul className="Progressbar">
        <li><span>情報の入力</span></li>
        <li><span>入力内容の確認</span></li>
        <li className="active"><span>送信・メールの確認</span></li>
        <li><span>申込み完了</span></li>
      </ul>

      <div className="txt">

        { ! sendCheck && <h3>送信中………</h3> }

        { sendCheck && <><h3>認証用メールの送信完了</h3><p>認証用メールをご登録のメールアドレスに送信しました。<br />
        ◆◆◆まだ申込みは完了しておりません◆◆◆<br /><br />
        メールを受信のうえ、本文に記載されているアドレス(URL)をクリックして、申込みのためのメール認証を完了してください。 <br /><br />※認証メールが届くまで、少々時間がかかる場合がございます。ご了承ください。 </p></> }

      </div>

      {false &&
      <div className="Form__btnbox">
        <div className="Form__btn-confirm">
        <input value="戻る" type="submit" id="back" onClick={submit} />
        </div>
      </div>
      }


  </>
 );
}
