import { React, useEffect, useState } from "react";
import { Outlet, useLocation, Link } from "react-router-dom";
import InfoGroup from "./InfoGroup";
import InfoIndivisual from "./InfoIndivisual";
import { useMyData } from './MyProvider';

export default function Info() {
  let location = useLocation();
  const { dataMy, myInfo,setSignupData } = useMyData();




  useEffect(() => {
    setSignupData({}, "");
  }, []);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);


  useEffect(() => {
    myInfo();
  }, []);

  useEffect(() => {
  }, [dataMy]);

  return (
    <>


      <div className="Signupselect">
        <h1 className="pagetopLabel">利用者情報</h1>
        {dataMy && dataMy.type == 0 && <InfoIndivisual />}
        {dataMy && dataMy.type == 1 && <InfoGroup />}
      </div>

    </>
  );
}
