import { React, useEffect, useState } from "react";
import { useMyData } from './MyProvider';
import ReserveAddPcCalDay from "./ReserveAddPcCalDay";


export default function ReserveAddSpCalDayRooms(props) {
  const {  dataPractice } = useMyData();
  const [holiday, setHoliday] = useState(undefined);

  useEffect(() => {
    if(dataPractice.ym) {
      if(dataPractice[dataPractice.ym]) {
        if(props.day.date) {
          const y = props.day.date.getFullYear();
          let m = props.day.date.getMonth() + 1;
          let d = props.day.date.getDate();
          if ( m < 10 ) {
            m = "0" + m;
          }
          if ( d < 10 ) {
            d = "0" + d;
          }
          const ymd = y + '-' + m + '-' + d;
          let hld = null;
          if(dataPractice[dataPractice.ym].cal[ymd]){
            hld = dataPractice[dataPractice.ym].cal[ymd].holiday;
          }
          setHoliday(hld);
        }
      }
    }
  }, [dataPractice.ym,props]);

  const wday = ['日','月','火','水','木','金','土'];

  return (
    <>
        <table className="CalendarRoom">
          <thead>
            <tr>
              <th rowSpan="2"></th>
              <th colSpan="3" className={holiday ? "sunday" : props.day.date.getDay()==0 ? "sunday" : props.day.date.getDay()==6 ? "saturday" : ""}>{props.day.date.getDate()}日（{wday[props.day.date.getDay()] + (holiday ? '・祝':'')}）{holiday}</th>
            </tr>
            <tr>
              <th>午前<span>（9:00〜12:00）</span></th>
              <th>午後<span>（13:00〜16:30）</span></th>
              <th>夜間<span>（17:30〜22:00）</span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>練習室1</td>
              <ReserveAddPcCalDay key={props.day.key} day={props.day} holiday={holiday} room={101} dataPractice={props.dataPractice} enable={props.enable} />
            </tr>
            <tr>
              <td>練習室2</td>
              <ReserveAddPcCalDay key={props.day.key} day={props.day} holiday={holiday} room={102} dataPractice={props.dataPractice} enable={props.enable} />
            </tr>
            <tr>
              <td>練習室3</td>
              <ReserveAddPcCalDay key={props.day.key} day={props.day} holiday={holiday} room={103} dataPractice={props.dataPractice} enable={props.enable} />
            </tr>
            <tr>
              <td>練習室4</td>
              <ReserveAddPcCalDay key={props.day.key} day={props.day} holiday={holiday} room={104} dataPractice={props.dataPractice} enable={props.enable} />
            </tr>
            <tr>
              <td>練習室5</td>
              <ReserveAddPcCalDay key={props.day.key} day={props.day} holiday={holiday} room={105} dataPractice={props.dataPractice} enable={props.enable} />
            </tr>
            <tr>
              <td>練習室6</td>
              <ReserveAddPcCalDay key={props.day.key} day={props.day} holiday={holiday} room={106} dataPractice={props.dataPractice} enable={props.enable} />
            </tr>
          </tbody>
        </table>
    </>
  );

}
