import { React, useEffect, useState } from "react";
import { useMyData } from './MyProvider';
import { useInput, useCheckbox } from "./hooks";
import { useNavigate } from "react-router-dom";

export default function InfoGroupEdit() {
  const { dataMy, setSignupData, getSignupData, getAddressFromZip } = useMyData();
  const navigate = useNavigate();

  const [signupData, signupMode] = getSignupData();

  const [r_tel1a, r_tel1b, r_tel1c] = dataMy.r_tel1.split('-');
  const [r_tel2a, r_tel2b, r_tel2c] = dataMy.r_tel2 ? dataMy.r_tel2.split('-') : ['', '', ''];
  const [j_tel1a, j_tel1b, j_tel1c] = dataMy.j_tel1 ? dataMy.j_tel1.split('-') : ['', '', ''];


  const [r_nameProps, resetRName] = useInput(signupData.r_name || dataMy.r_name); // 利用者団体名
  const [r_kanaProps, resetRKana] = useInput(signupData.r_kana || dataMy.r_kana, "kana"); // 利用者団体名カナ
  const [r_d_l_nameProps, resetRDLName] = useInput(signupData.r_d_l_name || dataMy.r_d_l_name); // 利用者代表者姓
  const [r_d_f_nameProps, resetRDFName] = useInput(signupData.r_d_f_name || dataMy.r_d_f_name); // 利用者代表者名
  const [r_d_l_kanaProps, resetRDLKana] = useInput(signupData.r_d_l_kana || dataMy.r_d_l_kana, "kana"); // 利用者代表者姓カナ
  const [r_d_f_kanaProps, resetRDFKana] = useInput(signupData.r_d_f_kana || dataMy.r_d_f_kana, "kana"); // 利用者代表者名カナ
  const [r_t_l_nameProps, resetRTLName] = useInput(signupData.r_t_l_name || ""); // 利用者担当者姓
  const [r_t_f_nameProps, resetRTFName] = useInput(signupData.r_t_f_name || ""); // 利用者担当者名
  const [r_t_l_kanaProps, resetRTLKana] = useInput(signupData.r_t_l_kana || ""); // 利用者担当者姓カナ
  const [r_t_f_kanaProps, resetRTFKana] = useInput(signupData.r_t_f_kana || ""); // 利用者担当者名カナ
  const [r_kubunProps, resetRKubun] = useInput(signupData.r_kubun || ""); // 利用者連絡区分(1:代表者 2:担当者)
  const [r_zipcodeProps, resetRZipcode] = useInput(signupData.r_zipcode || dataMy.r_zipcode, "number"); // 利用者郵便番号
  const [r_addressProps, resetAddress, r_addressSetValue] = useInput(signupData.r_address || dataMy.r_address); // 利用者住所
  const [r_tel1aProps, reseRTel1a] = useInput(signupData.r_tel1a || r_tel1a, "number"); // 利用者電話番号1a
  const [r_tel1bProps, reseRTel1b] = useInput(signupData.r_tel1b || r_tel1b, "number"); // 利用者電話番号1b
  const [r_tel1cProps, reseRTel1c] = useInput(signupData.r_tel1c || r_tel1c, "number"); // 利用者電話番号1c
  const [r_tel2aProps, reseRTel2a] = useInput(signupData.r_tel2a || r_tel2a, "number"); // 利用者電話番号2a
  const [r_tel2bProps, reseRTel2b] = useInput(signupData.r_tel2b || r_tel2b, "number"); // 利用者電話番号2b
  const [r_tel2cProps, reseRTel2c] = useInput(signupData.r_tel2c || r_tel2c, "number"); // 利用者電話番号2c
  const [r_faxProps, resetRFax] = useInput(signupData.r_fax || "", "number"); // 利用者FAX番号
  const [j_nameProps, resetJName] = useInput(signupData.j_name || ""); // 事務代行団体名
  const [j_kanaProps, resetJKana] = useInput(signupData.j_kana || ""); // 事務代行団体名カナ
  const [j_d_l_nameProps, resetJDLName] = useInput(signupData.j_d_l_name || dataMy.j_d_l_name); // 事務代行代表者姓
  const [j_d_f_nameProps, resetJDFName] = useInput(signupData.j_d_f_name || dataMy.j_d_f_name); // 事務代行代表者名
  const [j_d_l_kanaProps, resetJDLKana] = useInput(signupData.j_d_l_kana || dataMy.j_d_l_kana, "kana"); // 事務代行代表者姓カナ
  const [j_d_f_kanaProps, resetJDFKana] = useInput(signupData.j_d_f_kana || dataMy.j_d_f_kana, "kana"); // 事務代行代表者名カナ
  const [j_t_l_nameProps, resetJTLName] = useInput(signupData.j_t_l_name || ""); // 事務代行担当者姓
  const [j_t_f_nameProps, resetJTFName] = useInput(signupData.j_t_f_name || ""); // 事務代行担当者名
  const [j_t_l_kanaProps, resetJTLKana] = useInput(signupData.j_t_l_kana || ""); // 事務代行担当者姓カナ
  const [j_t_f_kanaProps, resetJTFKana] = useInput(signupData.j_t_f_kana || ""); // 事務代行担当者名カナ
  const [j_kubunProps, resetJKubun] = useInput(signupData.j_kubun || ""); // 事務代行連絡区分(1:代表者 2:担当者)'
  const [j_emailProps, resetJEmail] = useInput(signupData.j_email || ""); // 事務代行メールアドレス
  const [j_zipcodeProps, resetJZipcode] = useInput(signupData.j_zipcode || dataMy.j_zipcode, "number"); // 事務代行郵便番号
  const [j_addressProps, resetJAddress, j_addressSetValue] = useInput(signupData.j_address || dataMy.j_address); // 事務代行住所
  const [j_tel1aProps, resetJTel1a] = useInput(signupData.j_tel1a || j_tel1a, "number"); // 事務代行電話番号1a
  const [j_tel1bProps, resetJTel1b] = useInput(signupData.j_tel1b || j_tel1b, "number"); // 事務代行電話番号1b
  const [j_tel1cProps, resetJTel1c] = useInput(signupData.j_tel1c || j_tel1c, "number"); // 事務代行電話番号1c
  const [j_tel2aProps, resetJTel2a] = useInput(signupData.j_tel2a || "", "number"); // 事務代行電話番号2a
  const [j_tel2bProps, resetJTel2b] = useInput(signupData.j_tel2b || "", "number"); // 事務代行電話番号2b
  const [j_tel2cProps, resetJTel2c] = useInput(signupData.j_tel2c || "", "number"); // 事務代行電話番号2c
  const [j_faxProps, resetJFax] = useInput(signupData.j_fax || "", "number"); // 事務代行FAX番号
  const [emailProps, resetEmail] = useInput(signupData.email || ""); // Email(ログインID)(利用者メールアドレス)
  const [email1Props, resetEmail1] = useInput(signupData.email1 || ""); // Email(ログインID)(利用者メールアドレス)
  const [email2Props, resetEmail2] = useInput(signupData.email2 || ""); // Email(ログインID)(利用者メールアドレス)
  const [passwordProps, resetPassword] = useInput(signupData.password || ""); // パスワード
  const [password1Props, resetPassword1] = useInput(signupData.password1 || ""); // パスワード
  const [agreeProps] = useCheckbox(signupData.agree ? [...signupData.agree] : []); // 同意チェック


  const [hasError, setHasError] = useState(true); // エラーチェック
  const [load, setLoad] = useState(true); // 初期読み込み

  useEffect(() => {
    setLoad(false);
  }, []);

  // 郵便番号から住所を取得
  useEffect(() => {
    if (!load) {
      getAddressFromZip(r_zipcodeProps.value, r_addressSetValue);
    }
  }, [r_zipcodeProps.value]);

  // 担当者郵便番号から住所を取得
  useEffect(() => {
    if (!load) {
      getAddressFromZip(j_zipcodeProps.value, j_addressSetValue);
    }
  }, [j_zipcodeProps.value]);

  // エラーチェック
  useEffect(() => {
    let err = false;

    // 必須チェック
    if (r_nameProps.value === "") err = true;
    if (r_kanaProps.value === "") err = true;
    if (r_d_l_nameProps.value === "") err = true;
    if (r_d_f_nameProps.value === "") err = true;
    if (r_d_l_kanaProps.value === "") err = true;
    if (r_d_f_kanaProps.value === "") err = true;
    if (r_zipcodeProps.value === "") err = true;
    if (r_addressProps.value === "") err = true;
    if (r_tel1aProps.value === "") err = true;
    if (r_tel1bProps.value === "") err = true;
    if (r_tel1cProps.value === "") err = true;


    setHasError(err);
  });


  const back = (e) => {
    e.preventDefault();
    navigate('/info');
  }


  // 確認画面へ
  const submit = (e) => {
    e.preventDefault();

    setSignupData({
      r_name: r_nameProps.value,
      r_kana: r_kanaProps.value,
      r_d_l_name: r_d_l_nameProps.value,
      r_d_f_name: r_d_f_nameProps.value,
      r_d_l_kana: r_d_l_kanaProps.value,
      r_d_f_kana: r_d_f_kanaProps.value,
      r_zipcode: r_zipcodeProps.value,
      r_address: r_addressProps.value,
      r_tel1a: r_tel1aProps.value,
      r_tel1b: r_tel1bProps.value,
      r_tel1c: r_tel1cProps.value,
      r_tel2a: r_tel2aProps.value,
      r_tel2b: r_tel2bProps.value,
      r_tel2c: r_tel2cProps.value,
      j_d_l_name: j_d_l_nameProps.value,
      j_d_f_name: j_d_f_nameProps.value,
      j_d_l_kana: j_d_l_kanaProps.value,
      j_d_f_kana: j_d_f_kanaProps.value,
      j_zipcode: j_zipcodeProps.value,
      j_address: j_addressProps.value,
      j_tel1a: j_tel1aProps.value,
      j_tel1b: j_tel1bProps.value,
      j_tel1c: j_tel1cProps.value,
      email: emailProps.value,
      email1: email1Props.value,
      email2: email2Props.value,
      password: passwordProps.value,
      password1: password1Props.value,
      agree: ["agree"],
    }, "confirm");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }


  return (
    <>

      <form>

        <table className="Form" summary="#">
          <tbody>
            <tr>
              <th>団体名</th>
              <td>{dataMy.r_name}</td>
            </tr>
            <tr>
              <th>団体名フリガナ</th>
              <td>{dataMy.r_kana}</td>
            </tr>
            <tr>
              <th>代表者名 <span>※必須</span></th>
              <td>姓<input {...r_d_l_nameProps} size="20" className="w_20" type="text" required /> 名<input {...r_d_f_nameProps} size="20" className="w_20" type="text" required /></td>
            </tr>
            <tr>
              <th>代表者名フリガナ <span>※必須</span></th>
              <td>姓<input {...r_d_l_kanaProps} size="20" type="text" className="w_20" required /> 名<input {...r_d_f_kanaProps} size="20" className="w_20" type="text" required /></td>
            </tr>
            <tr>
              <th>ご住所 <span>※必須</span></th>
              <td>郵便番号<input type="text" {...r_zipcodeProps} autoComplete="postal-code" className="w_20" required /><span>（半角数字、ハイフンなし）</span><br />
                <input type="text" {...r_addressProps} required size="50" className="w_60" />
              </td>
            </tr>
            <tr>
              <th>電話番号1 <span>※必須</span></th>
              <td><input {...r_tel1aProps} size="10" className="w_10" type="text" maxLength="4" required />ー<input {...r_tel1bProps} size="10" className="w_10" type="text" maxLength="4" required />ー<input {...r_tel1cProps} size="10" className="w_10" type="text" maxLength="4" required /><span>（半角数字）</span></td>
            </tr>
            <tr>
              <th>電話番号2</th>
              <td><input {...r_tel2aProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...r_tel2bProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...r_tel2cProps} size="10" className="w_10" type="text" maxLength="4" /><span>（半角数字）</span></td>
            </tr>
          </tbody>
        </table>

        <h6>担当者情報</h6>

        <table className="Form" summary="#">
          <tbody>
            <tr>
              <th>担当者名</th>
              <td>姓<input size="20" type="text" className="w_40" {...j_d_l_nameProps} /> 名<input size="20" className="w_40" type="text" {...j_d_f_nameProps} /></td>
            </tr>
            <tr>
              <th>担当者名フリガナ</th>
              <td>姓<input size="20" type="text" className="w_40" {...j_d_l_kanaProps} /> 名<input size="20" className="w_40" type="text" {...j_d_f_kanaProps} /></td>
            </tr>
            <tr>
              <th>ご住所</th>
              <td>郵便番号<input type="text" {...j_zipcodeProps} autoComplete="postal-code" className="w_20" /><span>（半角数字、ハイフンなし）</span><br />
                <input type="text" {...j_addressProps} size="50" className="w_60" /><br />
                <span>代表者と連絡先が異なる場合のみ入力</span>
              </td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td><input {...j_tel1aProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...j_tel1bProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...j_tel1cProps} size="10" className="w_10" type="text" maxLength="4" /><span>（半角数字）</span><br />
                <span>代表者と連絡先が異なる場合のみ入力</span></td>
            </tr>
          </tbody>
        </table>

        <div className="Form__btnbox">
          <div className="Form__btn-return">
            <input value="戻る" type="submit" id="back" onClick={back} />
          </div>
          <div className="Form__btn-confirm">
            <input value="確認画面へ" type="submit" id="next" onClick={submit} disabled={hasError} />
          </div>
        </div>
      </form>
    </>
  );
}
