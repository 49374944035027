import { React } from "react";
import { useMyData } from './MyProvider';


export default function ReserveAddPcCalDay(props) {
  const { setDataPractice, dataPractice } = useMyData();

  let dayClass='';
  if(props.day.date){
    if(props.holiday){
      dayClass='sunday';
    }else{
      if(props.day.date.getDay()===0){
        dayClass='sunday';
      }
      if(props.day.date.getDay()===6){
        dayClass='saturday';
      }
    }
  } 

  let req;
  req = props.dataPractice.req || [];
  
  let reserveda = 1;
  let reservedb = 1;
  let reservedc = 1;

  let rsvData = props.dataPractice[props.dataPractice.ym] ? props.dataPractice[props.dataPractice.ym].cal : [];
  
  if(props.day.date){
    let y = props.day.date.getFullYear();
    let m = props.day.date.getMonth() + 1;
    let d = props.day.date.getDate();

    if( m < 10 ){
      m = "0" + m;
    }
    if( d < 10 ){
      d = "0" + d;
    }
    if(rsvData && rsvData[y + '-' + m + '-' + d]) {
      let dayRsv = rsvData[y + '-' + m + '-' + d][props.room];

      if(dayRsv.close===1) { // 休日
        reserveda = 2;
        reservedb = 2;
        reservedc = 2;
      } else {
        if ( dayRsv.time1 > 0 ) {
          reserveda = dayRsv.time1;
        } else {
          reserveda = 0;
        }
        if ( dayRsv.time2 > 0 ) {
          reservedb = dayRsv.time2;
        } else {
          reservedb = 0;
        }
        if ( dayRsv.time3 > 0 ) {
          reservedc = dayRsv.time3;
        } else {
          reservedc = 0;
        }
      }
    }

  }


  const updateCheck = (e) => {
    let targetValue = e.target.value;
    //let term = targetValue.substr(-1);
    let date = targetValue.substr(0,targetValue.length -1 );

    if (req.includes(e.target.value)) {
      req = req.filter(item => item !== e.target.value);
    } else {
      req = [...req, e.target.value];
      req = req.filter(item => ( item === date + 'a' || item === date + 'b' || item === date + 'c' ) );
    }

    // 午前と夜間を選択したら、午後も自動的に選択
    if (req.includes(date + 'a') && req.includes(date + 'c')) {
      if (req.includes(date + 'b')) {
      } else {
        if(reservedb==0) {
          req = [...req, date + 'b'];
        } else {
          req = [e.target.value];
        }
      }
    }
 
    setDataPractice({
      ...dataPractice,
      req,
      reqFix:[],
    });
    
  }

  const id = props.day.date 
   ? props.room + '-' + props.day.date.getFullYear() + '-' + ( "0" + (props.day.date.getMonth() + 1) ).substr(-2) + '-' + ("0" + props.day.date.getDate()).substr(-2)
   : undefined;


  return (
    <>

      {props.day.date ? 
      <>
      {reserveda===0 && <td className={dayClass}><input type="checkbox" checked={req.includes(id + 'a') ? "checked" : ""} value={id + 'a'} onChange={updateCheck} id={'cal' + id + 'a'} disabled={ !props.enable } /><label htmlFor={'cal' + id + 'a'}>&#x25CB;</label></td>}
      {reserveda===1 && <td className={dayClass}>&#x00D7;</td>}
      {reserveda===10 && <td className={dayClass + " today"}>&#x25CB;</td>}
      {reserveda===11 && <td className={dayClass + " today"}>&#x00D7;</td>}
      {reserveda===2 && <td className={dayClass}><span className="notice">休</span></td>}
      
      {reservedb===0 && <td className={dayClass}><input type="checkbox" checked={req.includes(id + 'b') ? "checked" : ""} value={id + 'b'} onChange={updateCheck} id={'cal' + id + 'b'} disabled={ !props.enable } /><label htmlFor={'cal' + id + 'b'}>&#x25CB;</label></td>}
      {reservedb===1 && <td className={dayClass}>&#x00D7;</td>}
      {reservedb===10 && <td className={dayClass + " today"}>&#x25CB;</td>}
      {reservedb===11 && <td className={dayClass + " today"}>&#x00D7;</td>}
      {reservedb===2 && <td className={dayClass}><span className="notice">休</span></td>}
      
      {reservedc===0 && <td className={dayClass}><input type="checkbox" checked={req.includes(id + 'c') ? "checked" : ""} value={id + 'c'} onChange={updateCheck} id={'cal' + id + 'c'} disabled={ !props.enable } /><label htmlFor={'cal' + id + 'c'}>&#x25CB;</label></td>}
      {reservedc===1 && <td className={dayClass}>&#x00D7;</td>}
      {reservedc===10 && <td className={dayClass + " today"}>&#x25CB;</td>}
      {reservedc===11 && <td className={dayClass + " today"}>&#x00D7;</td>}
      {reservedc===2 && <td className={dayClass}><span className="notice">休</span></td>}
      </>
      :
      <><td></td><td></td><td></td></>
      }
    </>
  );

}
