import { React, useEffect,useState } from "react";
import { useMyData } from './MyProvider';

export default function ReserveAddConfirm() {
  const { dataPractice, setDataPractice } = useMyData();
  const [ title, setTitle ] = useState('');
  const [ title2, setTitle2 ] = useState('');
  const [ room, setRoom ] = useState(0);
  const [ price, setPrice ] = useState(0);

  const wday = ['日','月','火','水','木','金','土'];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    //練習室1　1日（木）　午前・午後（9:00〜16:30）
    if (dataPractice.reqFix && dataPractice.reqFix.length > 0 ) {
      let time1 = 0;
      let time2 = 0;
      let time3 = 0;
      let date = '';
      let r = 0;
      dataPractice.reqFix.forEach((e) => {
        console.log('reqFix');
        console.log(e);
        let found = e.match(/^10([1-6])-(\d{4}-\d{2}-\d{2})([abc])$/);
        if(found) {
          r = found[1];
          date = new Date(found[2]);
          if(found[3] == 'a') {
            time1 = 1;
          }
          if(found[3] == 'b') {
            time2 = 1;
          }
          if(found[3] == 'c') {
            time3 = 1;
          }
        }
      });

      // 午前（9:00〜12:00）
      // 午後（13:00〜16:30）
      // 夜間（17:30〜22:00）

      let stime = '17:30';
      let etime = '12:00';
      let times = [];

      if(time2==1){
        stime = '13:00';
      }
      if(time1==1){
        stime = '9:00';
      }

      if(time2==1){
        etime = '16:30';
      }
      if(time3==1){
        etime = '22:00';
      }

      if(time1==1){
        times.push('午前');
      }
      if(time2==1){
        times.push('午後');
      }
      if(time3==1){
        times.push('夜間');
      }

      setRoom(r);
      setTitle(
        '練習室' +  r + '　'
      );
      setTitle2(
        + (date.getMonth() + 1) + '月' + date.getDate() + '日（'+ wday[date.getDay()] +'）　'
        + (times.join('・')) + '（' + stime + '〜' + etime + '）'
      );

      let price_tmp = 0;
      if(r==2) { // 練習室２
        if(time1==1 && time2==1 && time3==1 ) { // 午前・午後・夜間
          price_tmp = 4200;
        } else if(time1==1 && time2==1 && time3==0 ) { // 午前・午後
          price_tmp = 2400;
        } else if(time1==0 && time2==1 && time3==1 ) { // 午後・夜間
          price_tmp = 3100;
        } else if(time1==1 && time2==0 && time3==0 ) { // 午前
          price_tmp = 1100;
        } else if(time1==0 && time2==1 && time3==0 ) { // 午後
          price_tmp = 1300;
        } else if(time1==0 && time2==0 && time3==1 ) { // 夜間
          price_tmp = 1800;
        }
      } else { // 練習室 1,3,4,5,6
        if(time1==1 && time2==1 && time3==1 ) { // 午前・午後・夜間
          price_tmp = 4800;
        } else if(time1==1 && time2==1 && time3==0 ) { // 午前・午後
          price_tmp = 2800;
        } else if(time1==0 && time2==1 && time3==1 ) { // 午後・夜間
          price_tmp = 3500;
        } else if(time1==1 && time2==0 && time3==0 ) { // 午前
          price_tmp = 1300;
        } else if(time1==0 && time2==1 && time3==0 ) { // 午後
          price_tmp = 1500;
        } else if(time1==0 && time2==0 && time3==1 ) { // 夜間
          price_tmp = 2000;
        }
      }
      setPrice(price_tmp);
    }
    console.log(dataPractice);
  }, [dataPractice]);


  const back = (e) => {
    e.preventDefault();

    setDataPractice({
      ...dataPractice,
      mode:'back',
    })
  }

  const submit = (e) => {
    e.preventDefault();

    setDataPractice({
      ...dataPractice,
      mode:'send',
    })

  }



  return (
    <>
      <h1 className="pagetopLabel">音楽練習室利用予約 確認</h1>
      <p>ご利用内容を確認し、予約を完了してください。</p>
      <table>
        <tbody>
          <tr>
          <th>利用日</th>
            <td>{title2}</td>
          </tr>
          <tr>
            <th>利用施設</th>
            <td>{title}</td>
          </tr>
          <tr>
            <th>利用内容</th>
            <td>
            {dataPractice.form.purpose==1 && '練習'}
            {dataPractice.form.purpose==2 && 'リハーサル'}
            {dataPractice.form.purpose==99 && ''}
            {dataPractice.form.purpose==99 && <>{dataPractice.form.purposeText}</>}
            </td>
          </tr>
          <tr>
            <th>利用ジャンル</th>
            <td>
            {dataPractice.form.kind==1 && 'クラシック音楽'}
            {dataPractice.form.kind==2 && 'ポピュラー音楽'}
            {dataPractice.form.kind==99 && 'その他の音楽'}
            </td>
          </tr>
          <tr>
            <th>利用人数</th>
            <td>{dataPractice.form.members}人</td>
          </tr>
          <tr>
            <th>使用楽器</th>
            <td>
            { dataPractice.form.instrument.includes("1") && <>声楽<br /></> }
            { dataPractice.form.instrument.includes("2") && <>弦楽器<br /></> }
            { dataPractice.form.instrument.includes("3") && <>鍵盤楽器（ピアノ）<br /></> }
            { dataPractice.form.instrument.includes("4") && <>鍵盤楽器（ピアノ以外）<br /></> }
            { dataPractice.form.instrument.includes("5") && <>木管楽器<br /></> }
            { dataPractice.form.instrument.includes("6") && <>金管楽器<br /></> }
            { dataPractice.form.instrument.includes("88") && <>打楽器</> }
            { dataPractice.form.instrument.includes("88") && <> ({dataPractice.form.instrument88})<br /></> }
            { false && dataPractice.form.instrument.includes("99") && <>その他</> }
            { dataPractice.form.instrument.includes("99") && <>{dataPractice.form.instrument99}<br /></> }
            </td>
          </tr>
          <tr>
            <th>利用料金</th>
            <td>{ price.toLocaleString() }円（附帯設備別途有料）</td>
          </tr>
        </tbody>
      </table>


      <div className="Form__btnbox">
        <div className="Form__btn-return">
          <input type="button" value="戻る" onClick={back} />
        </div>
        <div className="Form__btn-confirm">
          <input value="予約を完了する" type="submit" onClick={submit} id="next" />
        </div>
      </div>


    </>
  );

}
