import { React, useEffect,useState } from "react";
import { useMyData } from './MyProvider';
import { useNavigate } from "react-router-dom";
import ReserveAddPcCal from "./ReserveAddPcCal";
import ReserveAddForm from "./ReserveAddForm";
import ReserveAddSpCal from "./ReserveAddSpCal";
import ReserveAddSpCalDayRooms from "./ReserveAddSpCalDayRooms";

export default function ReserveAddTop() {
  const { data, getPractice, dataPractice, setPracticePreRsv,resetPracticePreRsv,setRoom,dataPracticeSpDay,setDataPracticeSpDay } = useMyData();
  const [month, setMonth] = useState();
  const [prevActive, setPrevActive] = useState(true);
  const [nextActive, setNextActive] = useState(true);
  const [months, setMonths] = useState([{year:2022,month:6,ym:202206}]);
  let navigate = useNavigate();

  useEffect(() => {
    let today = new Date();
    //today.setHours( today.getHours() - 9); // 9時から予約開始なので、カレンダーの表示開始も9時間ずらす
    let ym = [];
    for (let i=0;i<4;i++) {
      let todayYM = today.getFullYear() * 100 + today.getMonth() + 1;
      ym.push({
        year:today.getFullYear(),
        month:today.getMonth() + 1,
        ym:todayYM,
      })
      today.setDate(1);
      today.setMonth( today.getMonth() + 1);
    }
    setMonths(ym);
  }, []);

  useEffect(() => {
    if(dataPractice.ym) {
      let found=dataPractice.ym.match(/^(\d{4})(\d{2})$/);
      if(found.length > 0) {
        console.log(found[0],found[1],found[2]-1);
        let targetMonth = new Date( found[1],found[2]-1,1);
        setMonth(targetMonth);

        let today = new Date();
        let limitDay = new Date();
        limitDay.setMonth( limitDay.getMonth() + 3);
        let targetMonthYM = targetMonth.getFullYear() * 100 + targetMonth.getMonth();
        let todayYM = today.getFullYear() * 100 + today.getMonth();
        let limitDayYM = limitDay.getFullYear() * 100 + limitDay.getMonth();
        if( targetMonthYM > todayYM ) {
          setPrevActive( true );
        } else {
          setPrevActive( false );
        }
        if( targetMonthYM < limitDayYM ) {
          setNextActive( true );
        } else {
          setNextActive( false );
        }
      }
    }
    if(dataPractice.req && dataPractice.req.length > 0) {
       let req = dataPractice.req[0];
       let found = req.match(/^10([1-6])-/);
       if(found) {
         setRoom(found[1]);
       }
    } else {
      setRoom(0);
    }
  }, [dataPractice]);

  const nextMonth = (e) => {
    e.preventDefault();
    //console.log('nextMonth', month);

    let m = month.getMonth();
    let y = month.getFullYear();
    m=m+1; // jsの月は0-11なので1-12に修正
    m=m+1;
    if(m > 12) {
      m = 1;
      y = y + 1;
    }
    if(m < 10) {
      m = "0" + m;
    }
    getPractice(y + '' + m);
    setDataPracticeSpDay();
  }
  const prevMonth = (e) => {
    e.preventDefault();
    //console.log('prevMonth', month);

    let m = month.getMonth();
    let y = month.getFullYear();
    m=m+1; // jsの月は0-11なので1-12に修正
    m=m-1;
    if(m < 1) {
      m = 12;
      y = y - 1;
    }
    if(m < 10) {
      m = "0" + m;
    }
    getPractice(y + '' + m);
    setDataPracticeSpDay();
  }

  const gotoMonth = (e) => {
    e.preventDefault();
    getPractice(e.currentTarget.dataset.ym);
    setDataPracticeSpDay();
    console.log(months);
  }

  const cancel = (e) => {
    e.preventDefault();
    resetPracticePreRsv();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate( '/' );
  }

  const submit = (e) => {
    e.preventDefault();

    setPracticePreRsv();
  }


  //dataPractice

  return (
    <>
      <h1 className="pagetopLabel">音楽練習室利用予約</h1>

      <div className="s-reservemenu">
        <div className="s-reservemenu__possible">
          予約回数　残り<span>{dataPractice && dataPractice[dataPractice.ym] && <>{dataPractice[dataPractice.ym].maxcount - dataPractice[dataPractice.ym].mycount > 0 ? dataPractice[dataPractice.ym].maxcount - dataPractice[dataPractice.ym].mycount : 0}</>}</span>回
        </div>
      </div>
      <div className="txt"><p>ご希望の日程・練習室を選び、【選択（次へ）】をクリックしてください。［○：予約可 / ×：予約不可 / 休：休館日］<br />
      1回の予約でご予約いただけるのは、特定の利用日の1室の連続した利用区分となります。<br />
      ※本画面で利用当日の予約できません。利用当日の予約は電話(045-682-2020)にて承ります。利用当日分の空き状況は前日の情報となります。ご注意ください。</p></div>

      <div className="Calendar__pc">


        <ul className="CalendarMenu">
          <li>{prevActive && <a className="precal" onClick={prevMonth}>前月</a>}</li>
          <li>{month && <>{month.getFullYear()}</>}年<span>{month && <>{month.getMonth() + 1}</>}月</span></li>
          <li>{nextActive && <a className="nextcal" onClick={nextMonth}>次月</a>}</li>
        </ul>

      <div className="s-reservemenu">
        <ul className="s-reservemenu__month">
          { months.map((m,i)=>(<li key={m.ym}><a onClick={gotoMonth} data-ym={m.ym}>{m.month}月</a></li>)) }
        </ul>
      </div>
        <ReserveAddPcCal month={month} dataPractice={dataPractice} enable={dataPractice[dataPractice.ym] && dataPractice[dataPractice.ym].maxcount - dataPractice[dataPractice.ym].mycount > 0} />
      </div>

      <div className="Calendar__sp">
        <div className="l-fluid-gray">
          <div className="l-fluid__inner">
            <div className="Calendar__bkwhite">
              <ul className="CalendarMenu">
                <li>{prevActive && <a className="precal" onClick={prevMonth}>前月</a>}</li>
                <li>{month && <>{month.getFullYear()}</>}年<span>{month && <>{month.getMonth() + 1}</>}月</span></li>
                <li>{nextActive && <a className="nextcal" onClick={nextMonth}>次月</a>}</li>
              </ul>
              <div className="s-reservemenu">
                <ul className="s-reservemenu__month">
                  { months.map((m,i)=>(<li key={m.ym}><a onClick={gotoMonth} data-ym={m.ym}>{m.month}月</a></li>)) }
                </ul>
              </div>
              <ReserveAddSpCal month={month} dataPractice={dataPractice} enable={dataPractice[dataPractice.ym] && dataPractice[dataPractice.ym].maxcount - dataPractice[dataPractice.ym].mycount > 0} />
            </div>
            <ul className="CalendarCaption">
              <li className="closed">休館日</li>
              <li className="select">選択中</li>
            </ul>
          </div>
        </div>
        { dataPracticeSpDay && <ReserveAddSpCalDayRooms day={dataPracticeSpDay} month={month} dataPractice={dataPractice} enable={dataPractice[dataPractice.ym] && dataPractice[dataPractice.ym].maxcount - dataPractice[dataPractice.ym].mycount > 0} />}
      </div>

      <div className="Form__btnbox">
        {!(dataPractice.reqFix && dataPractice.reqFix.length > 0) &&
        <>
        <div className="Form__btn-return">
          <input type="button" value="戻る" onClick={cancel} />
        </div>
        <div className="Form__btn-confirm">
          <input value="選択 （次へ）" type="submit" onClick={submit} id="next" disabled={dataPractice.req && dataPractice.req.length > 0 ? false : true} />
        </div>
        </>
        }
      </div>

      <ReserveAddForm />

    </>
  );

}
