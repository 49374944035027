import { React, useState } from "react";
import { useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { styled } from '@mui/material/styles';

const Input = styled('input')({
  display: 'none',
});

export default function RoomInfo() {
  const { room } = useMyData();

  const [open, setOpen] = useState(false);

  const toggleOpen = (e) => {
    setOpen(!open);
  }

  return (
    <>
      {room == 1 &&
        <div className={"RoomInfo " + (open ? 'open' : 'close')} onClick={toggleOpen}>
          <div>
            <a className="RoomInfo__btn"></a>
            <h3 className="RoomInfo__title">音楽練習室1のご案内</h3>
            <table>
              <tbody>
                <tr><th>広さ</th>
                  <td>35m&#0178;</td></tr>
                <tr><th>人数の目安</th>
                  <td>椅子使用あり：8名<br />椅子使用なし：15名</td></tr>
                <tr><th>料金</th>
                  <td>午前（9:00〜12:00）：1,300円<br />
                    午後（13:00〜16:30）：1,500円<br />
                    夜間（17:30〜22:00）：2,000円</td></tr>
                <tr><th>附帯設備（別途有料/当日支払）</th>
                  <td>グランドピアノ（ヤマハ C3X）&yen;3,000、ピアノ椅子（チェロ椅子）&yen;350、折りたたみ椅子&yen;150、譜面台&yen;250、電源&yen;200/1kw</td></tr>
              </tbody>
            </table>
            <p>練習室貸出の詳細は<a href="https://yokohama-minatomiraihall.jp/guide/practice/index.html" target="blank">こちら</a>をご確認ください。</p>
          </div>
        </div>
      }

      {room == 2 &&
        <div className={"RoomInfo " + (open ? 'open' : 'close')} onClick={toggleOpen}>
          <div>
            <a className="RoomInfo__btn"></a>
            <h3 className="RoomInfo__title">音楽練習室2のご案内</h3>
            <table>
              <tbody>
                <tr><th>広さ</th>
                  <td>23m&#0178;</td></tr>
                <tr><th>人数の目安</th>
                  <td>椅子使用あり：5名<br />椅子使用なし：8名</td></tr>
                <tr><th>料金</th>
                  <td>午前（9:00〜12:00）：1,100円<br />
                    午後（13:00〜16:30）：1,300円<br />
                    夜間（17:30〜22:00）：1,800円</td></tr>
                <tr><th>附帯設備（別途有料/当日支払）</th>
                  <td>ピアノ椅子（チェロ椅子）&yen;350、折りたたみ椅子&yen;150、譜面台&yen;250、電源&yen;200/1kw</td></tr>
              </tbody>
            </table>
            <p><span class="notice">※練習室2にはピアノはありません。</span><br />
            練習室貸出の詳細は<a href="https://yokohama-minatomiraihall.jp/guide/practice/index.html" target="blank">こちら</a>をご確認ください。</p>
          </div>
        </div>
      }

      {room == 3 &&
        <div className={"RoomInfo " + (open ? 'open' : 'close')} onClick={toggleOpen}>
          <div>
            <a className="RoomInfo__btn"></a>
            <h3 className="RoomInfo__title">音楽練習室3のご案内</h3>
            <table>
              <tbody>
                <tr><th>広さ</th>
                  <td>39m&#0178;</td></tr>
                <tr><th>人数の目安</th>
                  <td>椅子使用あり：10名<br />椅子使用なし：15名</td></tr>
                <tr><th>料金</th>
                  <td>午前（9:00〜12:00）：1,300円<br />
                    午後（13:00〜16:30）：1,500円<br />
                    夜間（17:30〜22:00）：2,000円</td></tr>
                <tr><th>附帯設備（別途有料/当日支払）</th>
                  <td>グランドピアノ（カワイ RX-3）&yen;3,000、ピアノ椅子（チェロ椅子）&yen;350、折りたたみ椅子&yen;150、譜面台&yen;250、電源&yen;200/1kw</td></tr>
              </tbody>
            </table>
            <p>練習室貸出の詳細は<a href="https://yokohama-minatomiraihall.jp/guide/practice/index.html" target="blank">こちら</a>をご確認ください。</p>
          </div>
        </div>
      }

      {room == 4 &&
        <div className={"RoomInfo " + (open ? 'open' : 'close')} onClick={toggleOpen}>
          <div>
            <a className="RoomInfo__btn"></a>
            <h3 className="RoomInfo__title">音楽練習室4のご案内</h3>
            <table>
              <tbody>
                <tr><th>広さ</th>
                  <td>35m&#0178;</td></tr>
                <tr><th>人数の目安</th>
                  <td>椅子使用あり：8名<br />椅子使用なし：15名</td></tr>
                <tr><th>料金</th>
                  <td>午前（9:00〜12:00）：1,300円<br />
                    午後（13:00〜16:30）：1,500円<br />
                    夜間（17:30〜22:00）：2,000円</td></tr>
                <tr><th>附帯設備（別途有料/当日支払）</th>
                  <td>グランドピアノ（カワイ RX-3）&yen;3,000、ピアノ椅子（チェロ椅子）&yen;350、折りたたみ椅子&yen;150、譜面台&yen;250、電源&yen;200/1kw</td></tr>
              </tbody>
            </table>
            <p>練習室貸出の詳細は<a href="https://yokohama-minatomiraihall.jp/guide/practice/index.html" target="blank">こちら</a>をご確認ください。</p>
          </div>
        </div>
      }

      {room == 5 &&
        <div className={"RoomInfo " + (open ? 'open' : 'close')} onClick={toggleOpen}>
          <div>
            <a className="RoomInfo__btn"></a>
            <h3 className="RoomInfo__title">音楽練習室5のご案内</h3>
            <table>
              <tbody>
                <tr><th>広さ</th>
                  <td>39m&#0178;</td></tr>
                <tr><th>人数の目安</th>
                  <td>椅子使用あり：15名<br />椅子使用なし：25名</td></tr>
                <tr><th>料金</th>
                  <td>午前（9:00〜12:00）：1,300円<br />
                    午後（13:00〜16:30）：1,500円<br />
                    夜間（17:30〜22:00）：2,000円</td></tr>
                <tr><th>附帯設備（別途有料/当日支払）</th>
                  <td>アップライトピアノ（ヤマハ YU5）&yen;1,000、ピアノ椅子（チェロ椅子）&yen;350、折りたたみ椅子&yen;150、譜面台&yen;250、電源&yen;200/1kw</td></tr>
              </tbody>
            </table>
            <p>練習室貸出の詳細は<a href="https://yokohama-minatomiraihall.jp/guide/practice/index.html" target="blank">こちら</a>をご確認ください。</p>
          </div>
        </div>
      }

      {room == 6 &&
        <div className={"RoomInfo " + (open ? 'open' : 'close')} onClick={toggleOpen}>
          <div>
            <a className="RoomInfo__btn"></a>
            <h3 className="RoomInfo__title">音楽練習室6のご案内</h3>
            <table>
              <tbody>
                <tr><th>広さ</th>
                  <td>27m&#0178;</td></tr>
                <tr><th>人数の目安</th>
                  <td>椅子使用あり：5名<br />椅子使用なし：8名</td></tr>
                <tr><th>料金</th>
                  <td>午前（9:00〜12:00）：1,300円<br />
                    午後（13:00〜16:30）：1,500円<br />
                    夜間（17:30〜22:00）：2,000円</td></tr>
                <tr><th>附帯設備（別途有料/当日支払）</th>
                  <td>アップライトピアノ（ヤマハ YU5）&yen;1,000、ピアノ椅子（チェロ椅子）&yen;350、折りたたみ椅子&yen;150、譜面台&yen;250、電源&yen;200/1kw</td></tr>
              </tbody>
            </table>
            <p>音楽練習室貸出の詳細は<a href="https://yokohama-minatomiraihall.jp/guide/practice/index.html" target="blank">こちら</a>をご確認ください。</p>
          </div>
        </div>
      }
    </>
  );

}
