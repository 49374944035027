import { React, useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import BreadCrumbs from "./BreadCrumbs";
import SignUp from "./SignUp";
import SignUpGroup from './SignUpGroup';
import SignUpIndivisual from "./SignUpIndivisual";
import NotFound from "./NotFound";
import MailAuth from "./MailAuth";
import Top from "./Top";
import Forgot from "./Forgot";
import Password from "./Password";
import { useMyData } from './MyProvider';
import Concerts from "./Concerts";
import ConcertPrAdd from "./ConcertPrAdd";
import Apply from "./Apply";
import ApplicationAdd from "./ApplicationAdd";
import RoomInfo from "./RoomInfo";
import ReserveAdd from "./ReserveAdd";
import ReserveLog from "./ReserveLog";
import TopAuth from "./TopAuth";
import SignOutDialog from "./SignOutDialog";
import NewsPage from "./NewsPage";
import Info from "./Info";
import NewsList from "./NewsList";
import InfoEdit from "./InfoEdit";
import ClosePage from "./ClosePage";
import useLocationChange from "./useLocationChange";
import ConcertPrView from "./ConcertPrView";
import ApplyLog from "./ApplyLog";
import ApplyDetail from "./ApplyDetail";

export default function App() {
  const { data, signOut, authCheck } = useMyData();
  const navigate = useNavigate();

  const [signOutDialogOpen, setSignOutDialogOpen] = useState(false);

  useEffect(() => {
    var mynav = document.getElementById('mynavi');
    var gnavp = document.getElementById('gnavp');
    var content = document.getElementById('content');

    if (data.token) {
      mynav.hidden = false;
      //const memberName = document.getElementById('memberName');
      //memberName.innerText = data.name;
      gnavp.classList.add('pcnone');
      content.classList.add('Wrapper-mypage');
      content.classList.remove('Wrapper');

      /*
      const memberSignout = document.getElementById('memberSignout');
      memberSignout.onclick = (e) => {
        e.preventDefault();
        setSignOutDialogOpen(true);
        //signOut();
      };
      */

      const mypageButton = document.getElementById('mypageButton');
      if (mypageButton) {
        mypageButton.onclick = (e) => {
          e.preventDefault();
          navigate('/');
        };
      }
      const mypageButton01 = document.getElementById('mypageButton01');
      if (mypageButton01) {
        mypageButton01.onclick = (e) => {
          e.preventDefault();
          navigate('/');
        };
      }
      const mypageButton02 = document.getElementById('mypageButton02');
      if (mypageButton02) {
        mypageButton02.onclick = (e) => {
          e.preventDefault();
          navigate('/');
        };
      }
      const reserveButton = document.getElementById('reserveButton');
      if (reserveButton) {
        reserveButton.onclick = (e) => {
          e.preventDefault();
          if (process.env.REACT_APP_PREOPEN == 1) {
          } else {
            navigate('/reserve');
          }
        };
      }
      const applyButton = document.getElementById('applyButton');
      if (applyButton) {
        applyButton.onclick = (e) => {
          e.preventDefault();
          if (process.env.REACT_APP_PREOPEN >= 1) {
          } else {
            navigate('/apply');
          }
        };
      }
      const concertsButton = document.getElementById('concertsButton');
      if (concertsButton) {
        concertsButton.onclick = (e) => {
          e.preventDefault();
          navigate('/concerts');
        };
      }
      const infoButton = document.getElementById('infoButton');
      if (infoButton) {
        infoButton.onclick = (e) => {
          e.preventDefault();
          navigate('/info');
        };
      }



    } else {
      mynav.hidden = true;
      gnavp.classList.remove('pcnone');
      content.classList.remove('Wrapper-mypage');
      content.classList.add('Wrapper');
    }
  }, [data]);

  //useEffect(()=>{
  //  authCheck();
  //},[]);

  useLocationChange(() => {
    authCheck();
  });


  const closeDialog = (e) => {
    setSignOutDialogOpen(false);
  }

  if (process.env.REACT_APP_PREOPEN == 99) {
    return (
      <ClosePage />
    );
  } else {
    const d = new Date();
    const nowdate = `${d.getFullYear()}${(d.getMonth()+1).toString().padStart(2, '0')}${d.getDate().toString().padStart(2, '0')}${d.getHours().toString().padStart(2, '0')}${d.getMinutes().toString().padStart(2, '0')}`
                    .replace(/\n|\r/g, '');

    return (
      <>
        <BreadCrumbs />
        <div className="l-base">
          <div className="l-column">
            <SignOutDialog open={signOutDialogOpen} onClose={closeDialog} />
            <Routes>
              <Route path="/" element={<Top />}>
              </Route>
              <Route path="/forgot" element={<Forgot />} />
              <Route path="/signup" element={<SignUp />}>
                <Route path="group" element={<SignUpGroup />} />
                <Route path="indivisual" element={<SignUpIndivisual />} />
              </Route>
              <Route path="/mailauth/:uuid/:id/:mail" element={<MailAuth />}></Route>
              <Route path="/password/:forgot/:uuid/:mail" element={<Password />}></Route>

              { ( nowdate >= process.env.REACT_APP_CLOSE_START && nowdate <= process.env.REACT_APP_CLOSE_END ) ? // CLOSE期間
              <></>
                :
              <>
              {process.env.REACT_APP_PREOPEN >= 2 ?
                <>
                  <Route path="/reserve" element={data.token && !data.loading ? <ReserveAdd /> : <TopAuth />} />
                  <Route path="/reserve_log" element={data.token && !data.loading ? <ReserveLog /> : <TopAuth />} />
                  <Route path="/reserve_log/:page" element={data.token && !data.loading ? <ReserveLog /> : <TopAuth />} />
                  <>
                    {process.env.REACT_APP_PREOPEN >= 3 ?
                      <>
                        <Route path="/concerts" element={data.token && !data.loading ? <Concerts /> : <TopAuth />} />
                        <Route path="/concert/new" element={data.token && !data.loading ? <ConcertPrAdd /> : <TopAuth />} />
                        <Route path="/concert/:id/add" element={data.token && !data.loading ? <ConcertPrAdd /> : <TopAuth />} />
                        <Route path="/concertpr/:id" element={data.token && !data.loading ? <ConcertPrView /> : <TopAuth />} />
                      </>
                      :
                      <></>
                    }
                  </>
                </>
                : process.env.REACT_APP_PREOPEN >= 1 ?
                  <>
                    {process.env.REACT_APP_PREOPEN >= 3 ?
                      <>
                        <Route path="/concerts" element={data.token && !data.loading ? <Concerts /> : <TopAuth />} />
                        <Route path="/concert/new" element={data.token && !data.loading ? <ConcertPrAdd /> : <TopAuth />} />
                        <Route path="/concert/:id/add" element={data.token && !data.loading ? <ConcertPrAdd /> : <TopAuth />} />
                        <Route path="/concertpr/:id" element={data.token && !data.loading ? <ConcertPrView /> : <TopAuth />} />
                      </>
                      :
                      <></>
                    }
                  </>
                  : <>

                    <Route path="/apply" element={data.token && !data.loading ? <Apply /> : <TopAuth />} />
                    <Route path="/apply/:id" element={data.token && !data.loading ? <ApplyDetail /> : <TopAuth />} />
                    <Route path="/apply_log" element={data.token && !data.loading ? <ApplyLog /> : <TopAuth />} />
                    <Route path="/apply_log/:page" element={data.token && !data.loading ? <ApplyLog /> : <TopAuth />} />
                    <Route path="/application/:id/add" element={data.token && !data.loading ? <ApplicationAdd /> : <TopAuth />} />

                    <Route path="/reserve" element={data.token && !data.loading ? <ReserveAdd /> : <TopAuth />} />
                    <Route path="/reserve_log" element={data.token && !data.loading ? <ReserveLog /> : <TopAuth />} />
                    <Route path="/reserve_log/:page" element={data.token && !data.loading ? <ReserveLog /> : <TopAuth />} />


                    <Route path="/concerts" element={data.token && !data.loading ? <Concerts /> : <TopAuth />} />
                    <Route path="/concert/new" element={data.token && !data.loading ? <ConcertPrAdd /> : <TopAuth />} />
                    <Route path="/concert/:id/add" element={data.token && !data.loading ? <ConcertPrAdd /> : <TopAuth />} />
                    <Route path="/concertpr/:id" element={data.token && !data.loading ? <ConcertPrView /> : <TopAuth />} />

                  </>}
              </>}

              <Route path="/news" element={data.token && !data.loading ? <NewsList /> : <TopAuth />} />
              <Route path="/news/:page" element={data.token && !data.loading ? <NewsList /> : <TopAuth />} />
              <Route path="/news/page/:id" element={data.token && !data.loading ? <NewsPage /> : <TopAuth />} />

              <Route path="/info" element={data.token && !data.loading ? <Info /> : <TopAuth />} />
              <Route path="/info/edit" element={data.token && !data.loading ? <InfoEdit /> : <TopAuth />} />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <RoomInfo />
        </div>

      </>
    );
  }
}

