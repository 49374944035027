import { React, useState, useEffect } from "react";
import { Outlet, useLocation, Link } from "react-router-dom";
import { useMyData } from "./MyProvider";

export default function NewsItem(props) {
  const { readNews } = useMyData();

  const clickUrl = (e) => {
    readNews(props.item.id);
  }

  return (
    <>
      {props.item && <>
              <div className="Mypagenews__head">
                <p className={'Mypagenews__date' + (props.item.read ? '' : ' noreadicon')}>{props.item.sdate}</p>
              </div>
              {props.item.titleonly==1 ? 
                <>
                  <p className="Mypagenews__body">
                    {props.item.title.replace(/<[^>]+>/g, '')}
                  </p>
                </>
                :
                <>
                  <p className="Mypagenews__body">
                    {props.item.url ?
                    <a href={props.item.url} onClick={clickUrl}>{props.item.title.replace(/<[^>]+>/g, '')}</a>
                    :
                    <Link to={"/news/page/" + props.item.id}>{props.item.title.replace(/<[^>]+>/g, '')}</Link>
                    }
                  </p>
                </>
              }
      </>}
    </>
  );
}
