import { React, useEffect,useState } from "react";
import { useMyData } from './MyProvider';
import { useInput, useCheckbox, useRadio } from "./hooks";
import { useInView } from 'react-intersection-observer';
import { useNavigate } from "react-router-dom";

export default function ReserveAddForm() {
  const { data, getPracticeCapacity, dataPractice, setDataPractice, resetPracticePreRsv } = useMyData();
  const [ title, setTitle ] = useState('');
  const [ room, setRoom ] = useState(0);


  const [purposeProps] = useInput(dataPractice.form && dataPractice.form.purpose ? dataPractice.form.purpose : ""); // 利用内容(ジャンル)
  const [purposeTextProps] = useInput(dataPractice.form && dataPractice.form.purposeText ? dataPractice.form.purposeText : ""); // 利用内容(ジャンル)（その他）
  const [kindProps] = useRadio(dataPractice.form && dataPractice.form.kind ? dataPractice.form.kind : ""); // 利用目的
  const [membersProps] = useInput(dataPractice.form && dataPractice.form.members ? dataPractice.form.members : "","number"); // 利用人数
  const [instrumentProps,,setInstrumentProps] = useCheckbox(dataPractice.form && dataPractice.form.instrument ? dataPractice.form.instrument : []); // 利用楽器
  const [instrument88Props] = useInput(dataPractice.form && dataPractice.form.instrument88 ? dataPractice.form.instrument88 : ""); // 利用楽器(打楽器）
  const [instrument99Props] = useInput(dataPractice.form && dataPractice.form.instrument99 ? dataPractice.form.instrument99 : ""); // 利用楽器(その他）

  const [hasError, setHasError] = useState(true); // エラーチェック
  const [membersError, setMembersError] = useState(true); // 定員エラー

  const [agreeProps] = useCheckbox(dataPractice.form && dataPractice.form.agree ? [...dataPractice.form.agree] : []); // 同意チェック

  // 利用規約の最後の位置が現れたか
  const { ref, inView } = useInView({
    triggerOnce: true, // 最初の一度だけ実行
  });


  const wday = ['日','月','火','水','木','金','土'];

  useEffect(() => {
    if(dataPractice.capacity && Object.keys(dataPractice.capacity).length > 0) {
    } else {
      getPracticeCapacity();
    }
  }, [dataPractice]);


  useEffect(() => {
    //練習室1　1日（木）　午前・午後（9:00〜16:30）
    if (dataPractice.reqFix && dataPractice.reqFix.length > 0 ) {
      let time1 = 0;
      let time2 = 0;
      let time3 = 0;
      let date = '';
      let r = 0;
      dataPractice.reqFix.forEach((e) => {
        console.log('reqFix');
        console.log(e);
        let found = e.match(/^10([1-6])-(\d{4}-\d{2}-\d{2})([abc])$/);
        if(found) {
          r = found[1];
          date = new Date(found[2]);
          if(found[3] == 'a') {
            time1 = 1;
          }
          if(found[3] == 'b') {
            time2 = 1;
          }
          if(found[3] == 'c') {
            time3 = 1;
          }
        }
      });

      // 午前（9:00〜12:00）
      // 午後（13:00〜16:30）
      // 夜間（17:30〜22:00）

      let stime = '17:30';
      let etime = '12:00';
      let times = [];

      if(time2==1){
        stime = '13:00';
      }
      if(time1==1){
        stime = '9:00';
      }

      if(time2==1){
        etime = '16:30';
      }
      if(time3==1){
        etime = '22:00';
      }

      if(time1==1){
        times.push('午前');
      }
      if(time2==1){
        times.push('午後');
      }
      if(time3==1){
        times.push('夜間');
      }

      setRoom(r);
      setTitle(
        '練習室' +  r + '　'
        + (date.getMonth() + 1) + '月' + date.getDate() + '日（'+ wday[date.getDay()] +'）　'
        + (times.join('・')) + '（' + stime + '〜' + etime + '）'
      );
    }
    console.log(dataPractice);
  }, [dataPractice]);

  // エラーチェック
  useEffect(() => {
    let err = false;

    // 必須チェック
    if(purposeProps.value==="") err=true;
    if(kindProps.val==="") err=true;
    if(membersProps.value==="") err=true;
    if(membersProps.value < 1) err=true;

    if(dataPractice.capacity){
      if(parseInt(membersProps.value) > parseInt(dataPractice.capacity[100 + parseInt(room)])) {
        err=true;
        setMembersError(true);
      } else {
        setMembersError(false);
      }
    }

    if(room == 2 && instrumentProps.val.includes("3")) {
      setInstrumentProps(instrumentProps.val.filter(item => item != "3"));
    }
    if(instrumentProps.val.length==0) err=true;

    if(purposeProps.value==99) {
      if(purposeTextProps.value==="") err=true;
    }
    if(instrumentProps.val.includes("88")) {
      if(instrument88Props.value==="") err=true;
    }
    if(instrumentProps.val.includes("99")) {
      if(instrument99Props.value==="") err=true;
    }

    setHasError(err);
  });

  const submit = (e) => {
    e.preventDefault();

    let form = {};
    form.purpose = purposeProps.value;
    form.purposeText = purposeTextProps.value;
    form.kind = kindProps.val;
    form.members = membersProps.value;
    form.instrument = instrumentProps.val;
    form.instrument88 = instrument88Props.value;
    form.instrument99 = instrument99Props.value;
    form.agree = agreeProps.val;

    setDataPractice({
      ...dataPractice,
      mode:'confirm',
      form,
    })
  }

  const cancel = (e) => {
    e.preventDefault();

    resetPracticePreRsv();
  }

  return (
    <>
      {dataPractice.reqFix && dataPractice.reqFix.length > 0 &&
      <div className="Form l-fluid-gray">
        <div className="l-fluid__inner">
          <p>ご希望の練習室の予約はまだ完了しておりません。<br />予約は５分以内に完了させてください。</p>
          <h5>{title}</h5>
          <h6>1：利用内容</h6>
          <div>
            <select {...purposeProps}>
              <option value="">利用内容 選択</option>
              <option value="1">練習</option>
              <option value="2">リハーサル</option>
              <option value="99">その他</option>
            </select>
            <input type="text" {...purposeTextProps} disabled={purposeProps.value!=99} placeholder="その他入力(10文字以内)" maxLength={10} />
            { purposeProps.value==99 && purposeTextProps.value==="" &&
              <div><span className="notice">内容をご入力ください。</span></div>
            }
          </div>
          <h6>2：利用ジャンル</h6>
          <div className="">
            <label><input type="radio" {...kindProps} value="1" checked={kindProps.val=="1"} />クラシック音楽</label>&ensp;
            <label><input type="radio" {...kindProps} value="2" checked={kindProps.val=="2"} />ポピュラー音楽</label>&ensp;
            <label><input type="radio" {...kindProps} value="99" checked={kindProps.val=="99"} />その他の音楽</label>
          </div>
          <h6>3：利用人数</h6>
          <div>
            ※状況により、ご利用いただける人数は変動いたします。ご注意ください。<br />
            <input type="text" {...membersProps} required size="5" maxLength="2" />人 (定員 {dataPractice.capacity ? dataPractice.capacity[100 + parseInt(room)] : ''}人)
            { membersError && <><div><span className="notice">練習室の定員数を超過しています。</span></div></> }
          </div>
          <h6>4：使用楽器</h6>
          {room==2 && <span class="notice">※練習室２にはピアノはありません。</span>}
          <div>
            <label><input type="checkbox" checked={instrumentProps.val.includes("1")} value="1" {...instrumentProps} />声楽</label>&ensp;
            <label><input type="checkbox" checked={instrumentProps.val.includes("2")} value="2" {...instrumentProps} />弦楽器</label>&ensp;
            {room!=2 && <label><input type="checkbox" checked={instrumentProps.val.includes("3")} value="3" {...instrumentProps} />鍵盤楽器（ピアノ）</label>}&ensp;
            <label><input type="checkbox" checked={instrumentProps.val.includes("4")} value="4" {...instrumentProps} />鍵盤楽器（ピアノ以外）</label>&ensp;
            <label><input type="checkbox" checked={instrumentProps.val.includes("5")} value="5" {...instrumentProps} />木管楽器</label>&ensp;
            <label><input type="checkbox" checked={instrumentProps.val.includes("6")} value="6" {...instrumentProps} />金管楽器</label>&ensp;
            <div style={{display: 'inline-block'}}><label><input type="checkbox" checked={instrumentProps.val.includes("88")} value="88" {...instrumentProps} />打楽器</label><input type="text" {...instrument88Props} disabled={!instrumentProps.val.includes("88")} placeholder="打楽器入力（23文字以内）" size="22" maxLength={23} /></div>&ensp;
            <div style={{display: 'inline-block'}}><label><input type="checkbox" checked={instrumentProps.val.includes("99")} value="99" {...instrumentProps} />その他</label><input type="text" {...instrument99Props} disabled={!instrumentProps.val.includes("99")} placeholder="その他入力（23文字以内）" size="22" maxLength={23} /></div>
          </div>
          { instrumentProps.val.includes("88") && instrument88Props.value==="" &&
            <div><span className="notice">使用する打楽器をご入力ください。</span></div>
          }
          { instrumentProps.val.includes("99") && instrument99Props.value==="" &&
            <div><span className="notice">使用する楽器をご入力ください。</span></div>
          }
          <br />
          <p>注意事項をスクロールして最後までご確認ください。次に「確認、了承しました。」にチェックを入れてください。「確認画面へ進む」がクリックできるようになります。</p>
          <div className="Terms">
            <strong>【利用についての注意事項】</strong>
            <ul className="kome">
              <li>下記に該当する行為や利用はご遠慮ください。</li>
              <li>1．音楽練習室の利用目的から外れる利用<br />
                <ul className="disc">
                <li>不特定多数を対象とする講習会、発表会やコンサート等催物の開催</li>
                <li>有料でのレッスン・教室・催物等の営利を目的とする利用　　など</li>
                </ul>
              </li>
              <li>2．ホール、音楽練習室の管理上支障のあるとき<br />
                <ul className="disc">
                <li>施設や附帯設備を損壊、汚損又は滅失するおそれがある利用</li>
                <li>定員を超えた人数での利用</li>
                <li>音楽演習室利用の権利を、第三者との間での譲渡または転貸する行為</li>
                <li>他の施設利用者に影響をおよぼす行為　　など</li>
                </ul>
              </li>
              <li>※練習室は防音仕様ではないため、音漏れ･振動が大きいものについては、利用をご遠慮ください。同様の理由で、周囲の静寂を必要とする録音利用（コンクール審査音源の録音等）には向いておりません。</li>
            </ul><br />
            <strong>【キャンセルについて】</strong>
            <p>お客様都合のキャンセルは、ご遠慮ください。やむをえない理由でご利用ができなくなった場合は、必ず事前にホールまでお電話ください。キャンセルが一定の回数を超えた場合や、事前のご連絡なく音楽練習室を利用されなかった場合は、音楽練習室の予約・利用を制限させていただきます。充分ご検討のうえお申込みください。</p>
            <br />
            <strong>【損害の賠償】</strong>
            <p>音楽練習室の施設、附帯設備、備品等を汚損または紛失した場合には、その損害を弁償していただきます。</p>
            <br />
            <strong>【その他の注意事項】</strong>
            <ul className="disc">
              <li>利用許可を受けていない施設への立ち入り、また無断での附帯設備の使用はお断りします。</li>
              <li>火気、危険物、その他ホール管理上不適切であると認められる物品の持込みはお断りします。</li>
              <li>音楽練習室、および指定場所以外での飲食はご遠慮ください。</li>
            </ul>


            <div id="end" ref={ref}></div>
          </div>
          <br />
          <p className="Terms__check"><label><input type="checkbox" checked={agreeProps.val.includes("agree")} value="agree" {...agreeProps} disabled={!inView} />&nbsp;注意事項、「音楽練習室 ご利用時のお願い」を確認、了承しました。</label></p>

          <div className="Form__btnbox">
            <div className="Form__btn-return">
              <input type="button" value="選択取消" onClick={cancel} />
            </div>
            <div className="Form__btn-confirm">
              <input value="確認画面へ進む" type="submit" id="next" disabled={!agreeProps.val.includes("agree") || hasError} onClick={submit} />
            </div>
          </div>
        </div>
      </div>
      }
    </>
  );

}
