import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput, useCheckbox, useRadio } from "./hooks";
import { useInView } from 'react-intersection-observer';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

const Input = styled('input')({
  display: 'none',
});

export default function ApplicationAddForm02() {
  let {id} = useParams();

  const { dataApplication, setDataApplication,myInfo,dataMy,getAddressFromZip,postApplicationFile } = useMyData();

  const [dtypeProps] = useRadio(dataApplication.plan.dtype || 0); // 主催種別
  const [dtype1Props] = useInput(dataApplication.plan.dtype1 || 1); // 主催種別9の場合
  const [dtype2Props] = useInput(dataApplication.plan.dtype2 || 1); // 主催種別9の場合

  const [historyProps] = useRadio(dataApplication.plan.history || 1); // 使用履歴
  const [history1Props] = useInput(dataApplication.plan.history1 || 1); // 使用履歴3の場合

  const [pastProps] = useCheckbox(dataApplication.plan.past ? [...dataApplication.plan.past] : []); // 出演実績
  const [pastTextProps] = useInput(dataApplication.plan.pastText||""); // 出演実績公演
  const [pastOther, setPastOther] = useState(dataApplication.plan.pastOther || [{id:1,text:""}]); // 他ホールでの公演実績

  const [titleProps] = useInput(dataApplication.plan.title||""); // 公演名
  const [priceProps] = useRadio(dataApplication.plan.price || 1); // 入場料金最高額
  const [targetProps] = useRadio(dataApplication.plan.target || 1); // 対象入場者
  const [guestsProps] = useInput(dataApplication.plan.guests||"","number"); // 集客見込み
  const [ticketProps] = useRadio(dataApplication.plan.ticket || 1); // 入場方式
  const [playersProps] = useInput(dataApplication.plan.players||"","number"); // 出演者人数
  const [player, setPlayer] = useState(dataApplication.plan.player || [{id:1,text:""}]); // 出演者
  const [programs, setPrograms] = useState(dataApplication.plan.programs || [{id:1,text:""}]); // 予定曲目
  const [genreProps] = useRadio(dataApplication.plan.genre || 1); // 公演ジャンル
  const [partProps] = useCheckbox(dataApplication.plan.part ? [...dataApplication.plan.part] : []); // 使用楽器等
  const [part6Props] = useInput(dataApplication.plan.part6||1); // 使用楽器等が6(合唱)の場合

  const [typeProps] = useRadio(dataApplication.plan.type || 1); //実施体制
  const [type2Props] = useInput(dataApplication.plan.type2||""); // 実施体制2(その他)のテキスト
  const [type3Props] = useInput(dataApplication.plan.type3||""); // 実施体制3(複数団体)のテキスト

  const [formProps] = useRadio(dataApplication.plan.form || 1); //編成
  const [programProps] = useRadio(dataApplication.plan.program || 1); // 内容
  const [programTextProps] = useInput(dataApplication.plan.program_text||""); // 内容3その他テキスト
  const [recitalProps] = useCheckbox(dataApplication.plan.recital||[]); // 内容4発表会の選択肢
  const [paProps] = useRadio(dataApplication.plan.pa || 1); //PA
  const [lightingProps] = useRadio(dataApplication.plan.lighting || 1); //照明
  const [pianoProps] = useCheckbox(dataApplication.plan.piano ? [...dataApplication.plan.piano] : []); // フルコンサートグランドピアノ
  const [pianoNumProps] = useInput(dataApplication.plan.piano_num||1); // フルコンサート用グランドピアノ台数
  const [piano1Props] = useInput(dataApplication.plan.piano1||0); // フルコンサート用グランドピアノ1台目
  const [piano2Props] = useInput(dataApplication.plan.piano2||0); // フルコンサート用グランドピアノ2台目
  const [organProps] = useCheckbox(dataApplication.plan.organ ? [...dataApplication.plan.organ] : []); // パイプオルガン使用
  const [organistProps] = useInput(dataApplication.plan.organist||1); // オルガニスト
  const [organistnameProps] = useInput(dataApplication.plan.organistname||""); // オルガニスト氏名
  const [organistnameErr,setOrganistnameErr] = useState(false); // オルガニスト氏名エラー
  const [lotteryProps] = useRadio(dataApplication.plan.lottery || 0); // 抽選会への参加
  const [commentProps] = useInput(dataApplication.plan.comment||""); //

  const [selectedFile, setSelectedFile] = useState(); // ファイル登録用
	const [isFilePicked, setIsFilePicked] = useState(false); // 画像登録用
  const [isFileSizeTooBig, setIsFileSizeTooBig] = useState(false); // 画像登録用
  const [uploadedFile, setUploadedFile] = useState(dataApplication.plan.file); // 画像登録用

  const [agreeProps] = useCheckbox(dataApplication.plan.agree ? [...dataApplication.plan.agree] : []); // 同意チェック

  const [hasError, setHasError] = useState(true); // エラーチェック

  // 利用規約の最後の位置が現れたか
  const { ref, inView } = useInView({
    triggerOnce: true, // 最初の一度だけ実行
  });

  // エラーチェック
  useEffect(() => {
    let err = false;

    // 必須チェック
    if(dtypeProps.val==="") err=true;
    if(parseInt(dtypeProps.val,10)<1) err=true;
    if(historyProps.val==="") err=true;
    if(titleProps.value==="") err=true;
    if(priceProps.val==="") err=true;
    if(targetProps.val==="") err=true;
    if(guestsProps.value==="") err=true;
    if(guestsProps.value==0) err=true;
    if(ticketProps.val==="") err=true;
    if(playersProps.value==="") err=true;
    if(playersProps.value==0) err=true;
    //if(programs.length == 0 || programs[0].text==="") err=true;
    if(genreProps.val==="") err=true;
    if(lotteryProps.val==="") err=true;

    if(dataApplication.venue==10 && organProps.val.includes("1") && organistProps.value==1 && organistnameProps.value=="") {
      err=true;
      setOrganistnameErr(true);
    } else {
      setOrganistnameErr(false);
    }

    setHasError(err);
  });


  const pastOtherAdd = () => {
    let id = 1;
    if(pastOther.length) {
      id = pastOther.slice(-1)[0].id + 1;
    }
    setPastOther([
      ...pastOther,
      {
        id,
        text:"",
      }
    ]);
  }
  const pastOtherDel = (e) => {
    setPastOther(pastOther.filter(item => item.id != e.currentTarget.value));
  }
  const pastOtherUpdate = (e) => {
    const text = e.currentTarget.value;
    const id = e.currentTarget.dataset.id;
    if (typeof text === 'string') {
      if (id) {
        const newarray = pastOther.map(item => {
          if (item.id == id ) {
            return {
              ...item,
              text
            };
          } else {
            return item;
          }
        })
        setPastOther(newarray);
      }
    }
  }

  const playerAdd = () => {
    let id = 1;
    if(player.length) {
      id = player.slice(-1)[0].id + 1;
    }
    setPlayer([
      ...player,
      {
        id,
        text:"",
      }
    ]);
  }
  const playerDel = (e) => {
    setPlayer(player.filter(item => item.id != e.currentTarget.value));
  }
  const playerUpdate = (e) => {
    const text = e.currentTarget.value;
    const id = e.currentTarget.dataset.id;
    if (typeof text === 'string') {
      if (id) {
        const newarray = player.map(item => {
          if (item.id == id ) {
            return {
              ...item,
              text
            };
          } else {
            return item;
          }
        })
        setPlayer(newarray);
      }
    }
  }

  const programsAdd = () => {
    let id = 1;
    if(programs.length) {
      id = programs.slice(-1)[0].id + 1;
    }
    setPrograms([
      ...programs,
      {
        id,
        text:"",
      }
    ]);
  }
  const programsDel = (e) => {
    setPrograms(programs.filter(item => item.id != e.currentTarget.value));
  }
  const programsUpdate = (e) => {
    const text = e.currentTarget.value;
    const id = e.currentTarget.dataset.id;
    if (typeof text === 'string') {
      if (id) {
        const newarray = programs.map(item => {
          if (item.id == id ) {
            return {
              ...item,
              text
            };
          } else {
            return item;
          }
        })
        setPrograms(newarray);
      }
    }
  }

  const fileChange = (event) => {
    const file = event.target.files[0];
    if ( file.size > 10 * 1024 * 1024 ) {
      setIsFileSizeTooBig(true);
      setIsFilePicked(false);
      setSelectedFile(undefined);
    } else {
      setIsFileSizeTooBig(false);
      setSelectedFile(file);
      setIsFilePicked(true);
      postApplicationFile(id,file,setUploadedFile);
    }
	};

  const makeData = () => {
    let plan = {};

    //apply.r_name = r_nameProps.value;
    //apply.r_d_l_name = r_d_l_nameProps.value;
    plan.dtype = dtypeProps.val;
    plan.dtype1 = dtype1Props.value;
    plan.dtype2 = dtype2Props.value;

    plan.history = historyProps.val;
    plan.history1 = history1Props.value;

    plan.past = pastProps.val;
    plan.pastText = pastTextProps.value;
    plan.pastOther = pastOther;

    plan.title = titleProps.value;
    plan.price = priceProps.val;
    plan.target = targetProps.val;
    plan.guests = guestsProps.value;
    plan.ticket = ticketProps.val;
    plan.players = playersProps.value;
    plan.player = player;
    plan.programs = programs;
    plan.genre = genreProps.val;
    plan.part = partProps.val;
    plan.part6 = part6Props.value;

    plan.type = typeProps.val;
    plan.type2 = type2Props.value;
    plan.type3 = type3Props.value;

    plan.form = formProps.val;
    plan.program = programProps.val;
    plan.program_text = programTextProps.value;
    plan.recital = recitalProps.val;
    plan.pa = paProps.val;
    plan.lighting = lightingProps.val;
    plan.piano = pianoProps.val;
    plan.piano_num = pianoNumProps.value;
    plan.piano1 = piano1Props.value;
    plan.piano2 = piano2Props.value;
    plan.organ = organProps.val;
    plan.organist = organistProps.value;
    plan.organistname = organistnameProps.value;
    plan.lottery = lotteryProps.val;
    plan.comment = commentProps.value;

    plan.file = uploadedFile;
    plan.file_name = selectedFile ? selectedFile.name : dataApplication.plan.file_name;
    plan.agree = agreeProps.val;

    return plan;
  }

  const nextStep = () => {
    window.scrollTo({
      top: 0,
    });
    const plan = makeData();

    setDataApplication({
      ...dataApplication,
      "mode": "step6",
      plan
    })
  };

  const prevStep = () => {
    window.scrollTo({
      top: 0,
    });
    const plan = makeData();

    setDataApplication({
      ...dataApplication,
      "mode": "step4",
      plan
    })
  };
  return (
    <>
      <h1 className="pagetopLabel">公演計画情報</h1>

      <ul className="Progressbar">
        <li className="complete"><span>第一希望選択</span></li>
        <li className="complete"><span>第二希望選択</span></li>
        <li className="complete"><span>第三希望選択</span></li>
        <li className="complete"><span>利用許可<br />申請情報の入力</span></li>
        <li className="active"><span>公演計画<br />申請情報の入力</span></li>
        <li><span>確認</span></li>
        <li><span>抽選申込完了</span></li>
      </ul>

      <div className="txt"><p>公演計画について詳細を入力してください。</p></div>
      <table className="Form" summary="#">
        <tbody><tr>
          <th>主催者の概要 <span>※必須</span></th>
          <td>
            <label><input type="radio" {...dtypeProps} value="1" checked={dtypeProps.val=="1"} />行政</label>
            <label><input type="radio" {...dtypeProps} value="2" checked={dtypeProps.val=="2"} />楽団</label>
            <label><input type="radio" {...dtypeProps} value="3" checked={dtypeProps.val=="3"} />音楽事務所</label>
            <label><input type="radio" {...dtypeProps} value="4" checked={dtypeProps.val=="4"} />アマチュア団体</label>
            <label><input type="radio" {...dtypeProps} value="5" checked={dtypeProps.val=="5"} />個人</label>
            <label><input type="radio" {...dtypeProps} value="6" checked={dtypeProps.val=="6"} />NPO</label>
            <label><input type="radio" {...dtypeProps} value="7" checked={dtypeProps.val=="7"} />法人</label>
            <label><input type="radio" {...dtypeProps} value="8" checked={dtypeProps.val=="8"} />その他</label><br />
            <label><input type="radio" {...dtypeProps} value="9" checked={dtypeProps.val=="9"} />教育機関</label>

            <select {...dtype1Props} disabled={dtypeProps.val!="9"}>
              <option value="1">公立（横浜市立）</option>
              <option value="2">公立（県立）</option>
              <option value="3">公立（他）</option>
              <option value="4">私立</option>
            </select>

            <select {...dtype2Props} disabled={dtypeProps.val!="9"}>
              <option value="1">幼稚園</option>
              <option value="2">小学校</option>
              <option value="3">中学校</option>
              <option value="4">高校</option>
              <option value="5">大学</option>
              <option value="6">その他学校</option>
            </select>

          </td>
        </tr>
        <tr>
          <th rowSpan="4">当ホール使用履歴 <span>※必須</span></th>
          <td>
            <label><input type="radio" {...historyProps} value="1" checked={historyProps.val=="1"} />初申請</label>
            <label><input type="radio" {...historyProps} value="2" checked={historyProps.val=="2"} />申請は2回目以上だが、主催としての利用実績無し</label><br />
            <label><input type="radio" {...historyProps} value="3" checked={historyProps.val=="3"} />主催として同団体で利用実績あり</label>
            <select {...history1Props} disabled={historyProps.val!="3"} >
              <option value="1">1回</option>
              <option value="2">2回</option>
              <option value="3">3回以上</option>
            </select>
        </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>当ホール使用履歴</th>
          <td>
            <span>※初申請の場合、団体の活動履歴・開催希望公演の実績（他ホールでのチラシ・プログラムなど）を1つご提出ください。</span><br />
            {false &&
            <>
            <input type="file" name="file1" accept=".png, .jpg, .jpeg, .pdf, .doc" /><br />
            <span>画像形式（PDF/JPG・PNG）、ファイルサイズ（３MB以下）</span>
            </>
            }

            <label style={{padding:0, marginBottom:"1vw"}}>
              <Input accept=".png, .jpg, .jpeg, .gif, .pdf, .dox, .docx, .ppt, .pptx" id="contained-button-file" type="file" onChange={fileChange} />
              <Button variant="contained" component="span" style={{margin:0}} >
                画像 または PDFファイル選択
              </Button>
            </label>
            {uploadedFile &&  <span>(<a href={process.env.REACT_APP_API_ENDPOINT + 'my/application/' + id + '/file/' + uploadedFile}>ファイルあり</a>)</span>}

            {uploadedFile && false ?
            <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  image={process.env.REACT_APP_API_ENDPOINT + 'my/application/' + id + '/file/' + uploadedFile}
                />
            </Card>
            : ''}
            {selectedFile && <>
              <span>{selectedFile.name} </span>
              <span>({ Math.ceil(selectedFile.size / 1000) } kb)</span>
            </>}
            {isFileSizeTooBig ? <span className="err">ファイルサイズが大きすぎます</span> : ''}
            <br />
            <span>画像形式（PDF/JPG・PNG）、ファイルサイズ（３MB以下）</span>
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>当ホール使用履歴</th>
          <td>
            <span>※まだ主催としてご利用実績がない団体の方のみお答えください。</span><br />
            <label><input type="checkbox" checked={pastProps.val.includes("1")} value="1" {...pastProps} />他主催公演に同団体として出演実績あり</label>
              <input type="text"  {...pastTextProps} disabled={! pastProps.val.includes("1")} placeholder="公演詳細" />
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>当ホール使用履歴</th>
          <td>
            <span>他ホールでの公演実績</span><br />
            { pastOther.map((item) => (
            <div key={item.id}><input type="text" data-id={item.id} onChange={pastOtherUpdate} value={item.text} size="40" placeholder="他ホール名（公演年月日）" /><button type="button" className="additionbtn" onClick={pastOtherDel} value={item.id}><i className="fas fa-minus"></i></button></div>
            ))}
            { pastOther.length < 3 &&
            <button type="button" className="additionbtn" onClick={pastOtherAdd}><i className="fas fa-plus"></i></button>
            }
            <br />
            <span>（例）○×ホール（2022年１月１日）（入力欄は３つまで増やせます。）</span>
          </td>
        </tr>
        <tr>
          <th>公演名<span>※必須</span></th>
          <td><input type="text" {...titleProps} required size="80" /></td>
        </tr>
        <tr>
          <th>入場料金等の最高額<span>※必須</span></th>
          <td>
            {dataApplication.venue==10 &&
            <>
            <label><input type="radio" {...priceProps} value="1" checked={priceProps.val=="1"} />無料～2,000円</label>
            <label><input type="radio" {...priceProps} value="2" checked={priceProps.val=="2"} />2,001円～5,000円</label>
            <label><input type="radio" {...priceProps} value="3" checked={priceProps.val=="3"} />5,001円以上</label><br />
            </>
            }

            {dataApplication.venue==20 &&
            <>
            <label><input type="radio" {...priceProps} value="1" checked={priceProps.val=="1"} />無料～3,000円</label>
            <label><input type="radio" {...priceProps} value="2" checked={priceProps.val=="2"} />3,001円以上</label><br />
            </>
            }
            <span>※入場料金等のうちで最高額を選択してください。※当日増も含め、料金の最高額によって利用料金が決まります。</span>
          </td>
        </tr>
        <tr>
          <th>対象入場者<span>※ステップ１で入力済</span></th>
          <td>
            <label><input type="radio" {...targetProps} value="1" checked={targetProps.val=="1"} disabled="disabled" />一般</label>
            <label><input type="radio" {...targetProps} value="2" checked={targetProps.val=="2"} disabled="disabled" />参加者のみ</label>
            <label><input type="radio" {...targetProps} value="3" checked={targetProps.val=="3"} disabled="disabled" />関係者のみ</label>
          </td>
        </tr>
        <tr>
          <th>集客見込み<span>※ステップ１で入力済</span></th>
          <td><input type="text" required size="10" {...guestsProps} disabled="disabled" />名程度<span>（半角数字）</span></td>
        </tr>
        <tr>
          <th>入場方式<span>※ステップ１で入力済</span></th>
          <td>
            <label><input type="radio" {...ticketProps} value="1" checked={ticketProps.val=="1"} disabled="disabled" />指定席</label>
            <label><input type="radio" {...ticketProps} value="2" checked={ticketProps.val=="2"} disabled="disabled" />当日指定引換</label>
            <label><input type="radio" {...ticketProps} value="3" checked={ticketProps.val=="3"} disabled="disabled" />自由席（チケット発行あり）</label>
            <label><input type="radio" {...ticketProps} value="4" checked={ticketProps.val=="4"} disabled="disabled" />自由席（チケット発行なし）</label><br />
            {dataApplication.venue==10 &&
            <span>※自由席については<a href="">開催条件</a>があります。別途ご確認ください。</span>
            }
          </td>
        </tr>
        <tr>
          <th>出演者数<span>※必須</span></th>
          <td><input type="text" required size="10" {...playersProps} />名<span>（半角数字）</span></td>
        </tr>
        <tr>
          <th>主な出演者/演奏団体</th>
          <td>
            { player.map((item) => (
            <div key={item.id}><input type="text" data-id={item.id} onChange={playerUpdate} value={item.text} size="35" placeholder="（例）みなと太郎（指揮）" /><button type="button" className="additionbtn" onClick={playerDel} value={item.id}><i className="fas fa-minus"></i></button></div>
            ))}
            {player.length < 3 &&
            <button type="button" className="additionbtn" onClick={playerAdd}><i className="fas fa-plus"></i></button>
            }
            <br />
            <span>（例）みなと太郎（指揮）（１つの入力欄に１名･１団体を入力/入力欄は３つまで増やせます）</span>
          </td>
        </tr>
        <tr>
          <th>予定曲目</th>
          <td>
            { programs.map((item) => (
            <div key={item.id}><input type="text" data-id={item.id} onChange={programsUpdate} value={item.text} size="40" placeholder="（例）ベートーヴェン:ピアノ協奏曲第5番「皇帝」" /><button type="button" className="additionbtn" onClick={programsDel} value={item.id}><i className="fas fa-minus"></i></button></div>
            ))}
            {programs.length < 3 &&
            <button type="button" className="additionbtn" onClick={programsAdd}><i className="fas fa-plus"></i></button>
            }
            <br />
            <span>（例）ベートーヴェン:ピアノ協奏曲第5番 変ホ長調「皇帝」（入力欄は３つまで増やせます）</span>
          </td>
        </tr>
        <tr>
          <th>公演ジャンル<span>※必須</span></th>
          <td>
            <label><input type="radio" {...genreProps} value="1" checked={genreProps.val=="1"} />クラシック音楽</label>
            <label><input type="radio" {...genreProps} value="2" checked={genreProps.val=="2"} />ポピュラー音楽</label>
            <label><input type="radio" {...genreProps} value="3" checked={genreProps.val=="3"} />その他の音楽</label>
          </td>
        </tr>
        <tr>
          <th rowSpan="6">公演の概要</th>
          <td>
            <span className="ttl">使用楽器等</span><br />
            <label><input type="checkbox" checked={partProps.val.includes("1")} value="1" {...partProps} />声楽</label>
            <label><input type="checkbox" checked={partProps.val.includes("2")} value="2" {...partProps} />弦楽器</label>
            <label><input type="checkbox" checked={partProps.val.includes("3")} value="3" {...partProps} />管楽器</label>
            <label><input type="checkbox" checked={partProps.val.includes("4")} value="4" {...partProps} />鍵盤楽器</label>
            <label><input type="checkbox" checked={partProps.val.includes("5")} value="5" {...partProps} />打楽器</label>
            <label><input type="checkbox" checked={partProps.val.includes("6")} value="6" {...partProps} />合唱</label>
            <select {...part6Props} disabled={!partProps.val.includes("6")}>
              <option value="1">女声</option>
              <option value="2">男声</option>
              <option value="3">混声</option>
              <option value="4">児童・生徒</option>
            </select>
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>公演の概要</th>
          <td>
            <span className="ttl">実施体制</span><br />
            <label><input type="radio" {...typeProps} value="1" checked={typeProps.val=="1"} />1団体</label>
            <div><label><input type="radio" {...typeProps} value="2" checked={typeProps.val=="2"} />その他</label><input type="text" placeholder="その他入力" {...type2Props} disabled={typeProps.val != "2"} /></div>
            <div><label><input type="radio" {...typeProps} value="3" checked={typeProps.val=="3"} />複数団体（教室）合同</label>
            <textarea placeholder="団体（教室）名" cols="60" {...type3Props} disabled={typeProps.val!="3"} /></div>
            </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>公演の概要</th>
          <td>
            <span className="ttl">編成</span><br />
            <label><input type="radio" {...formProps} value="1" checked={formProps.val=="1"} />ソロ</label>
            <label><input type="radio" {...formProps} value="2" checked={formProps.val=="2"} />アンサンブル</label>
            <label><input type="radio" {...formProps} value="3" checked={formProps.val=="3"} />オーケストラ・吹奏楽・合唱（80名未満）</label>
            <label><input type="radio" {...formProps} value="4" checked={formProps.val=="4"} />オーケストラ・吹奏楽・合唱（80名以上）</label>
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>公演の概要</th>
          <td>
            <span className="ttl">内容</span><br />
            <label><input type="radio" {...programProps} value="1" checked={programProps.val=="1"} />演奏会</label>
            <label><input type="radio" {...programProps} value="2" checked={programProps.val=="2"} />フェスティバル・大会</label>
            <label><input type="radio" {...programProps} value="3" checked={programProps.val=="3"} />その他</label>
              <input type="text" {...programTextProps} disabled={programProps.val!="3"} placeholder="その他入力" /><br />
              <div>
                <label><input type="radio" {...programProps} value="4" checked={programProps.val=="4"} />発表会</label>
                （<label><input type="checkbox" checked={recitalProps.val.includes("1")} value="1" {...recitalProps} disabled={programProps.val!="4"} />幼児</label>
                <label><input type="checkbox" checked={recitalProps.val.includes("2")} value="2" {...recitalProps} disabled={programProps.val!="4"} />小中学生</label>
                <label><input type="checkbox" checked={recitalProps.val.includes("3")} value="3" {...recitalProps} disabled={programProps.val!="4"} />高校生</label>
                <label><input type="checkbox" checked={recitalProps.val.includes("4")} value="4" {...recitalProps} disabled={programProps.val!="4"} />大学〜大学院生</label>
                <label><input type="checkbox" checked={recitalProps.val.includes("5")} value="5" {...recitalProps} disabled={programProps.val!="4"} />成年</label>）
              </div>
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>公演の概要</th>
          <td>
            <label><input type="radio" {...paProps} value="1" checked={paProps.val=="1"} />PAなし</label>
            <label><input type="radio" {...paProps} value="2" checked={paProps.val=="2"} />PAあり（主催者業者手配あり）</label>
            <label><input type="radio" {...paProps} value="3" checked={paProps.val=="3"} />PAあり（主催者業者手配なし）</label></td>
        </tr>
        <tr>
          <th style={{display: "none"}}>公演の概要</th>
          <td>
            <label><input type="radio" {...lightingProps} value="1" checked={lightingProps.val=="1"} />照明演出なし</label>
            <label><input type="radio" {...lightingProps} value="2" checked={lightingProps.val=="2"} />照明演出あり（主催者業者手配あり）</label>
            <label><input type="radio" {...lightingProps} value="3" checked={lightingProps.val=="3"} />照明演出あり（主催者業者手配なし）</label>
          </td>
        </tr>
        <tr>
          <th rowSpan={dataApplication.venue==10 ? 2 : 1}>ホール所有楽器の使用希望</th>
          <td>
            <span>※ご希望の使用機種を入力してください。ホール利用状況によりご希望に沿えない場合があります。パイプオルガンの使用には別途使用申込書が必要です。</span><br />
            <label><input type="checkbox" checked={pianoProps.val.includes("1")} value="1" {...pianoProps} />フルコンサートグランドピアノ</label>
            <select {...pianoNumProps} disabled={! pianoProps.val.includes("1")} >
              <option value="1">1台</option>
              <option value="2">2台</option>
            </select><br />
            1台目
            <select {...piano1Props} disabled={! pianoProps.val.includes("1")}>
              <option value="0">機種指定なし</option>
              <option value="1">スタインウェイD274</option>
              {piano2Props.value!="2" && <option value="2">ベーゼンドルファーインペリアル</option>}
              {piano2Props.value!="3" && <option value="3">ヤマハCFX</option>}
            </select>
            &nbsp;&nbsp;
            2台目
            <select {...piano2Props} disabled={! pianoProps.val.includes("1") || pianoNumProps.value != "2"}>
              <option value="0">機種指定なし</option>
              <option value="1">スタインウェイD274</option>
              {piano1Props.value!="2" && <option value="2">ベーゼンドルファーインペリアル</option>}
              {piano1Props.value!="3" && <option value="3">ヤマハCFX</option>}
            </select><br />
          </td>
        </tr>
        {dataApplication.venue==10 &&
        <tr>
          <th style={{display: "none"}}>ホール所有楽器の使用希望</th>
          <td>
            <label><input type="checkbox" checked={organProps.val.includes("1")} value="1" {...organProps} />パイプオルガン</label>
            <select {...organistProps} disabled={!organProps.val.includes("1")} >
              <option value="1">オルガニスト確定</option>
              <option value="2">オルガニスト未定</option>
              <option value="3">オルガニストの斡旋希望</option>
            </select>
          <input type="text" {...organistnameProps} disabled={!organProps.val.includes("1")||organistProps.value!=1} placeholder="氏名入力" />
            {organistnameErr && <span className="err">オルガニスト氏名を入力してください</span>}{organistnameErr}
          </td>
        </tr>
        }
        <tr>
          <th>抽選会への参加 <span>※必須</span></th>
          <td>
            <span>抽選会への参加を希望されますか。</span><br />
            <label><input type="radio" {...lotteryProps} value="0" checked={lotteryProps.val=="0"} />希望しません（ホール代行抽選）</label>
            <label><input type="radio" {...lotteryProps} value="1" checked={lotteryProps.val=="1"} />希望します（来館抽選）</label>
          </td>
        </tr>
        <tr>
          <th>備考メモ欄</th>
          <td>
            <textarea {...commentProps} placeholder="ホールへの連絡にお使いください。（最大200字）" cols="80" maxLength="200" />
          </td>
        </tr>

      </tbody>
      </table>

      <p>以下、「{dataApplication.venue==10 ? '大' : '小'}ホールにおける施設利用方法について」をスクロールしてご確認のうえ、「同意する」ボタンをクリックしてください。</p>
      <div className="Terms">
        <strong>横浜みなとみらいホール施設利用ウェブシステム　利用規約</strong><br /><br />
        （趣旨）<br />
        <ul className="kiyaku">
          <li>第1条　横浜みなとみらいホール施設利用ウェブシステム（以下「ウェブシステム」という。）は、横浜みなとみらいホール施設の利用にあたり、インターネットを介したホール公式ウェブサイトからの音楽練習室利用申込み、大小ホール利用抽選申込み、および公演情報のフォーム入力を可能とすることでホール利用者の利便性を高めるとともに、横浜みなとみらいホール貸館運営の効率化を図ることを目的に、横浜みなとみらいホール指定管理者（以下「ホール」という。）が設置するウェブシステムです。この規約は、適正かつ公平なウェブシステム運用を行うため、ウェブシステムの利用に際し必要となる内容について定めるものです。
          </li>
        </ul>
        （規約の承認）<br />
        <ul className="kiyaku">
          <li>
          第2条　ウェブシステムを利用して施設の予約等の手続きを行うためには、本規約への同意が必要となります。ウェブシステムを利用しようとする者（以下「利用者」という）は、本規約に同意したものとみなされ、ホールは規約の同意を前提に利用者へウェブシステムのサービスを提供します。何らかの理由により本規約への同意が確認できない場合は、ウェブシステムをご利用いただけません。
          </li>
        </ul>

        （利用者登録）<br />
        <ul className="kiyaku">
          <li>第3条　本規約に同意のうえ、ウェブシステムの利用に必要な登録申請を、インターネットを介して、または所定の登録申請方法によりホールに対して行い、ホールがその申請を承認して登録した個人または団体の利用者を登録者とします。
          </li>
          <li>　　２&nbsp;登録者は、ホールが登録者の氏名、団体名、住所および電話番号等の情報をウェブシステムおよび横浜みなとみらいホール施設予約管理システム内で管理し、業務上必要な範囲内で利用することを了承するものとします。
          </li>
        </ul>

        （利用者登録の条件）<br />
        <ul className="kiyaku">
          <li>第4条　個人または団体が利用者の登録をしようとする場合は、次の条件を満たすこととします。</li>
          <li>　　(1)&nbsp;同じ個人、または同じ団体がウェブシステムに登録されていないこと</li>
          <li>　　(2)&nbsp;同じIDがウェブシステムに利用者の登録がなされていないこと</li>
          <li>　　２&nbsp;利用者の登録は、1IDにつき、一個人または一団体の登録ができるものとします。</li>
        </ul>
        （IDおよびパスワードの設定、管理）<br />
        <ul className="kiyaku">
          <li>第5条　ホールは、登録者が指定する電子メールアドレスを当該登録者に係るID（以下「ID」という。）としてウェブシステムに登録します。</li>
          <li>　　２&nbsp;ホールは、登録者が指定する文字列を当該登録者に係るパスワード（以下「パスワード」という。）としてウェブシステムに登録します。</li>
          <li>　　３&nbsp;登録者は、善良な管理者の注意をもって、IDおよびパスワードを適切に管理するものとします。また特定のIDおよびパスワードにより行われた行為については、その登録者により行われた行為とみなします。</li>
          <li>　　４&nbsp;IDおよびパスワードの使用あるいは管理に際し、登録者に起因する理由で、IDおよびパスワードが不正に使用され、その結果登録者に何らかの不利益が生じたとき、あるいはホール、ウェブシステムまたは横浜みなとみらいホール施設、または他の利用者に損害を与えた場合は、当該登録者がその一切の責めを負うものとします。</li>
        </ul>
        （IDおよびパスワードの紛失、漏洩、盗難）<br />
        <ul className="kiyaku">
          <li>第6条　登録者は、IDおよびパスワードを紛失、あるいは漏洩、または盗まれた場合は、直ちにその旨をホールへ届け出るものとします。この場合において、ホールは、当該IDおよびパスワードの利用を停止することができるものとします。</li>
          <li>　　２&nbsp;前項の届出を行うまでの間において、登録者のIDおよびパスワードによってなされた行為は、登録者によって行われたものとみなします。</li>
          <li>　　３&nbsp;前項の届出を行うまでの間において、当該IDおよびパスワードを第三者に不正に使用され、その結果登録者に何らかの不利益が生じた場合、あるいはホール、ウェブシステム、横浜みなとみらいホール施設、または他の利用者に損害を与えた場合は、当該登録者がその一切の責めを負うものとします。</li>
        </ul>
        （登録情報の字体）<br />
        <ul className="kiyaku">
          <li>第7条　登録者の申請内容に使われる字体が、ウェブシステムにおいて取扱いが困難な字体である場合は、類似する標準字体（JIS 第一、第二水準）で登録するものとします。この場合において、ウェブシステムで表示される字体並びに郵送物等の字体は標準字体となります。</li>
        </ul>
        （登録の廃止）<br />
        <ul className="kiyaku">
          <li>第8条　登録者が所定の方法でウェブシステムの登録廃止の意志を申し出、ホールがこれを承認した場合、当該登録を廃止するものとします。</li>
        </ul>
        （施設利用申込み等）<br />
        <ul className="kiyaku">
          <li>第9条　登録者は、パソコンまたはタブレット、携帯電話等によってインターネットを通じ、ウェブシステムにアクセスし、そのIDおよびパスワードを入力することにより、次のサービスを受けることができます。</li>
          <li>　　(1)&nbsp;音楽練習室の予約(当日の予約を除く)</li>
          <li>　　(2)&nbsp;ホール利用に関わる公演情報の登録･提出</li>
          <li>　　２&nbsp;前項のサービスは、利用期間内24時間の受付を基本としますが、ウェブシステムのメンテナンスのため利用できない日または時間帯が生じる場合があります。</li>
          <li>　　３&nbsp;第1項第1号は、ホールが別途定める件数を上限として同時に複数の予約ができるものとします。</li>
        </ul>
        （音楽練習室利用予約の取消）<br />
        <ul className="kiyaku">
          <li>第10条	登録者は、第9条第１項第１号に規定する音楽練習室の予約について、登録者のやむを得ない都合により予約の取消を希望する場合は、事前に所定の方法で取消を申し出、ホールがこれを承認した場合、当該予約を取消できるものとします。</li>
        </ul>
        （ウェブシステム登録事項の変更）<br />
        <ul className="kiyaku">
          <li>第11条	登録者は、ウェブシステムに登録した氏名、住所等に変更が生じた場合は、遅滞なく、インターネットまたは所定の方法により登録事項の変更手続を行うものとします。</li>
          <li>　　２&nbsp;前項の手続がないために、ホールからの通知または送付書類その他のものが延着または到着しなかったことにより生じる不利益または損害について、ホールは責任を負わないものとします。</li>
        </ul>
        （登録者の利用停止および取消）<br />
        <ul className="kiyaku">
          <li>第12条	ホールは、登録者または登録団体が次のいずれかに該当した場合には、ウェブシステムの利用を停止し、またはその登録を取り消すことができるものとします。また停止、もしくは取消された場合に生じる不利益や損害について、ホールは責任を負わないものとします。</li>
          <li>　　(1)&nbsp;ウェブシステムの登録や利用において虚偽の申告をした場合</li>
          <li>　　(2)&nbsp;本規約に違反した場合</li>
          <li>　　(3)&nbsp;第11条第1項に規定する手続がされない場合</li>
          <li>　　(4)&nbsp;登録者の責に帰すべき事由によりその所在が不明となり、当該登録者への通知・連絡が不可能であるとホールが判断した場合</li>
          <li>　　(5)&nbsp;ウェブシステム対象施設が規定されている条例および条例規則等に違反した場合</li>
          <li>　　(6)&nbsp;ウェブシステムおよび施設の利用に際し、ホールの指示に従わない場合</li>
          <li>　　(7)&nbsp;不適切な利用方法により他の利用者の利用の妨げになるとホールが判断した場合</li>
          <li>　　(8)&nbsp;第10条に規定する音楽練習室予約の取消回数が、ホールが別途定める回数を超えた場合</li>
          <li>　　(9)&nbsp;第10条に規定する音楽練習室予約の取消を行わず、予約当日ホールに来館せず、音楽練習室の利用を行わない回数が、ホールが別途定める回数を超えた場合</li>
          <li>　&nbsp;(10)&nbsp;ウェブシステムおよび施設の管理･運営上支障があるとき、そのほかホールが必要と認めたとき</li>
        </ul>
        （禁止行為）<br />
        <ul className="kiyaku">
          <li>第13条	ウェブシステムの利用に当たっては、次に掲げる行為を禁止します。</li>
          <li>　　(1)&nbsp;ウェブシステムを施設予約以外の目的で利用すること</li>
          <li>　　(2)&nbsp;ウェブシステムの管理および運営を妨害、破壊すること</li>
          <li>　　(3)&nbsp;ウェブシステムに対し、不正にアクセスすること</li>
          <li>　　(4)&nbsp;ウェブシステムに対し、ウィルスに感染したファイルや、悪意のあるプログラムを含むファイルを送信すること</li>
          <li>　　(5)&nbsp;ウェブシステム、ウェブシステムに含まれるプログラム、およびその他著作物の修正、複製、改ざん、販売等の行為</li>
          <li>　　(6)&nbsp;他人もしくは他団体のID、パスワード等を不正に使用すること。</li>
          <li>　　(7)&nbsp;その他法令等に違反すると認められる行為をすること</li>
          <li>　　２&nbsp;ホールは、ウェブシステムに対し前項(1)から(6)のいずれかに該当する行為が明らかな場合、又は該当する行為があると疑うに足りる相当な理由がある場合は、利用者から収集した情報の抹消、ウェブシステムの停止等の必要な措置を行うことができるものとします。</li>
        </ul>
        （免責事項）<br />
        <ul className="kiyaku">
          <li>第14条	次の各項目に対してホールはその一切の責任を負いません。</li>
          <li>　　(1)&nbsp;利用者が本ウェブシステムを利用したことにより発生した利用者の損害、および利用者が第三者に与えた損害</li>
          <li>　　(2)&nbsp;利用者への事前の通告の有無を問わず、ホールがその裁量において行った、ウェブシステムの改修、運用停止、中断等のために生じた利用者の損害</li>
          <li>　　(3)&nbsp;ウェブシステムの利用者が使用するパソコンまたはタブレット、携帯電話等機器の障害、不具合、通信回線上の障害、その他、ホールの責めに帰さない理由によるウェブシステムの障害等により発生した利用者の損害、および、利用者が第三者に与えた損害</li>
        </ul>
        （著作権）<br />
        <ul className="kiyaku">
          <li>第15条	本ウェブシステムに含まれているプログラムおよびその他著作物に関する著作権は、ホールに帰属します。</li>
        </ul>
        （個人情報の保護）<br />
        <ul className="kiyaku">
          <li>第16条	ウェブシステムおよびウェブシステム対象施設の利用のために提供を受けた個人情報は、「公益財団法人横浜市芸術文化振興財団プライバシーポリシー」（https://p.yafjp.org/privacy_policy/）に則り、適切に取扱います。</li>
          <li>　　２&nbsp;個人情報の収集・利用・管理は、ホールのほか、ウェブシステムおよび横浜みなとみらいホール施設予約管理システムの管理･運営に関わる受託業者が行います。</li>
          <li>　　３&nbsp;ウェブシステムの利用・運用、対象施設の利用・運営、およびそれに伴う予約管理業務（料金収納業務や登録者から提供された公演情報を基に行う広報業務を含む）以外の目的で、登録者の同意を得ずに個人情報の利用、または第三者への情報提供を行うことはありません。ただし、次のいずれかに該当する場合を除きます。</li>
          <li>　　(1)&nbsp;裁判所・警察等法執行機関等から、法令に基づき情報の開示を求められた場合</li>
          <li>　　(2)&nbsp;人の生命、身体または財産を保護するため、緊急かつやむを得ないとホールが判断した場合</li>
        </ul>
        （規約の変更、承認）<br />
        <ul className="kiyaku">
          <li>第17条	ホールは、必要があると認めるときは、利用者への事前の通知を行うことなく、本規約を変更することができるものとします。</li>
          <li>　　２&nbsp;本規約の変更後にウェブシステムを利用したときは、利用者は変更後の規約に同意したものとみなします。</li>
        </ul>
        附　則  <br />
        （施行期日） <br />
        この規約は、令和4年7月1日から施行する。 <br />
        附　則  <br />
        （施行期日） <br />
        この規約は、令和4年10月28日から施行する。<br />


        <div id="end" ref={ref}></div>
      </div>
      <p className="Terms__check"><label><input type="checkbox" checked={agreeProps.val.includes("agree")} value="agree" {...agreeProps} disabled={!inView} />&nbsp;ホール申請・利用についての案内・規約を確認、および了承しました。</label></p>

      <div className="Form__btnbox">
        <div className="Form__btn-return">
          <input type="button" value="戻る" onClick={prevStep} />
        </div>
        <div className="Form__btn-confirm">
          <input value="確認画面へ" type="submit" id="next" disabled={!agreeProps.val.includes("agree") || hasError } onClick={nextStep} />
        </div>
      </div>


    </>
  );

}
