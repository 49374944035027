import { React, useEffect, useState } from "react";
import { Outlet, useLocation,Link } from "react-router-dom";
import InfoGroupConfirm from "./InfoGroupConfirm";
import InfoGroupEdit from "./InfoGroupEdit";
import InfoGroupSend from "./InfoGroupSend";
import InfoIndivisualConfirm from "./InfoIndivisualConfirm";
import InfoIndivisualEdit from "./InfoIndivisualEdit";
import InfoIndivisualSend from "./InfoIndivisualSend";
import { useMyData } from './MyProvider';

export default function InfoEdit() {
  let location = useLocation();
  const { dataMy,myInfo,getSignupData } = useMyData();

  const [signupData, signupMode] = getSignupData();


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);


  useEffect(() => {
    myInfo();
  },[]);

  useEffect(() => {
  },[dataMy]);

  return (
    <>
        

        <div className="Signupselect">
          <h1 className="pagetopLabel">利用者情報</h1>
          {dataMy && dataMy.type==0 && signupMode==='confirm' && <InfoIndivisualConfirm />}
          {dataMy && dataMy.type==0 && signupMode==='send' && <InfoIndivisualSend />}
          {dataMy && dataMy.type==0 && (!signupMode || signupMode==='' || signupMode==='back') && <InfoIndivisualEdit />}

          {dataMy && dataMy.type==1 && signupMode==='confirm' && <InfoGroupConfirm />}
          {dataMy && dataMy.type==1 && signupMode==='send' && <InfoGroupSend />}
          {dataMy && dataMy.type==1 && (!signupMode || signupMode==='' || signupMode==='back') && <InfoGroupEdit />}
        </div>

    </>
 );
}
