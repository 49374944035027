import { Button } from "@mui/material";
import { isValidDateValue } from "@testing-library/user-event/dist/utils";
import { React, useEffect,useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useMyData } from './MyProvider';

export default function ReserveLog() {
  const { getReserves,dataPracticeRsv,cancelReserve } = useMyData();
  
  const [pages, setPages] = useState([]);
  const [rsvData, setRsvData] = useState([]);

  const params = useParams();
  const page = params.page || 1;

  const pp = 20;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getReserves();
  }, []);

  useEffect(() => {
    let d = [];
    if(dataPracticeRsv.data){
      dataPracticeRsv.data.forEach((e) => {
        let stime = '17:30';
        let etime = '12:00';
        let times = [];

        if(e.time2==1){
          stime = '13:00';
        }
        if(e.time1==1){
          stime = '9:00';
        }

        if(e.time2==1){
          etime = '16:30';
        }
        if(e.time3==1){
          etime = '22:00';
        }

        if(e.time1==1){
          times.push('午前');
        }
        if(e.time2==1){
          times.push('午後');
        }
        if(e.time3==1){
          times.push('夜間');
        }

        d.push({
          id: e.id,
          date: e.sdate_t,
          room: '練習室' + (parseInt(e.venue) - 100),
          time: times.join('・'),
          stime: stime,
          etime: etime,
          members: e.members,
          status: e.status,
        });
      });

      setRsvData(d);
      if(d.length > 0) {
        const thisD = d.slice((pp * (page - 1)), (pp * page));
        setRsvData([...thisD])
        let pagesCalc = [];
        let i=1;
        while(1){
          pagesCalc.push(i);
          if(i * pp >= d.length) {
            break;
          }
          i++;
        }
        setPages(pagesCalc);
      }

    }
  }, [dataPracticeRsv,page]);

  const cancel = (e) => {
    cancelReserve(e.currentTarget.value);
  }

  const scrollTop = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      <h1 className="pagetopLabel">音楽練習室 予約履歴</h1>
      <ul className="s-reservelist">
        {rsvData.map((item) => (
          <li key={item.id}>
            <span className="date">{item.date} </span>
            <span className="room">{item.room} </span>
            <span className="time">
            <span>{item.time} </span>
            <span>({item.stime}〜{item.etime}) </span></span>
            <span>{item.members}人 </span>
            {item.status==1 && false &&
              <Button variant="outlined" value={item.id} onClick={cancel} className="cancel">キャンセル</Button>
            }
            {item.status!=1 &&
              <span className="canceldone">キャンセル済</span>
            }
          </li>
        ))}
        {rsvData.length > 0 &&
        <ul className="pager">
          {page > 1 &&
            <li className="prev">
              <Link to={'/reserve_log/' + (page - 1)} onClick={scrollTop}>««</Link>
            </li>
          }
          {pages.map((item) => (
            <li key={item}>
              { (item == page) ?
              <span>{item}</span>
              :
              <Link to={'/reserve_log/' + item} onClick={scrollTop}>{item}</Link>
              }
            </li>
          ))}
          {[...pages].reverse()[0] != page &&
            <li className="next">
              <Link to={'/reserve_log/' + (1 + parseInt(page))} onClick={scrollTop}>»»</Link>
            </li>
          }
        </ul>
        }
        {rsvData.length===0 && <li>予約はありません。</li>}
      </ul>
    </>
  );
}
