import { React, useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useMyData } from './MyProvider';

export default function NewsPage() {
  let {id} = useParams();
  const { news,readNews,getNews } = useMyData();
  const [newsData, setNewsData] = useState([]);
  const navigate = useNavigate();



  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    readNews(id);
    if(news.length == 0) {
      getNews();
    }
  },[]);

  useEffect(()=>{
    const data = news.filter(item => item.id == id);
    setNewsData(data);
  },[news]);

  const back = (e) => {
    e.preventDefault();
    navigate(-1);
  }

  const dateFormat = (date) => {
    const wday = [ "日", "月", "火", "水", "木", "金", "土" ];

    const match = date.match(/^(\d+)年(\d+)月(\d+)日$/);
    let ret = '';
    if(match) {
      console.log(match[1]);
      console.log(match[2]);
      console.log(match[3]);
      const d = new Date(match[1],match[2] - 1,match[3]);
      ret = date + '(' + wday[ d.getDay() ] + ')';
    }
    return ret;
  }

  return (
    <div className="newspage">
      {newsData.map(item=>(

        <div key={item.id}>

        <div className="Titlebox">
          <div className="titleLabel">
          <h1 className="titleLabel__main" dangerouslySetInnerHTML={ { __html: item.title } } />
          <p className="titleLabel__sub">{ dateFormat(item.sdate) }</p>
          </div>
        </div>

        <div dangerouslySetInnerHTML={ { __html: item.content } } />
        </div>
      ))}
      <div className="Form__btnbox">
        <div className="Form__btn-return">
          <Link to='/' title="https://mypage.yokohama-minatomiraihall.jp/" onClick={back}>戻る</Link>
        </div>
      </div>
    </div>
  );
}
