import { React, useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { useMyData } from './MyProvider';

export default function ReserveAddFin(props) {
  const { dataPractice, setDataPractice, setPracticeRsv } = useMyData();
  const [ title, setTitle ] = useState('');
  const [ room, setRoom ] = useState(0);

  const wday = ['日','月','火','水','木','金','土'];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if(props.mode == 'send'){
      setPracticeRsv();
    }
  }, []);


  const submit = (e) => {
    setDataPractice({
      ...dataPractice,
      mode:'back',
    })
  }



  return (
    <>
        <h1 className="pagetopLabel">音楽練習室利用予約 完了</h1>
        <h3>音楽練習室予約{dataPractice.mode == 'fin' ? '完了' : '中...'}</h3>
        {dataPractice.mode == 'fin' &&
        <div>
          <div className="txt"><p>音楽練習室の予約が完了しました。<br />予約の履歴は、【マイページ】→【音楽練習室予約履歴】よりご確認いただけます。</p></div>
        
        <div className="txt"><h6>利用当日について</h6><p>ご利用当日は、けやき通り沿いにある横浜みなとみらいホール楽屋口よりご入館ください。利用料金と附帯設備利用料は、ご入館時にお支払いいただきます。支払後、附帯設備を追加利用された場合は、退室時にご精算ください。<br />なお、予約されている利用区分の時間内は、自由に入退室ができます。</p></div>
        
        <div className="txt"><h6>利用のキャンセルについて</h6><p>お客様都合のキャンセルは、ご遠慮ください。やむをえない理由でご利用ができなくなった場合は、必ず事前に横浜みなとみらいホール運営チーム（045-682-2020）までご連絡ください。 <br />できるだけ多くのお客様にご利用いただくためにも、ご協力をお願いいたします。</p></div>
        </div>
        }
        {dataPractice.mode == 'fin' &&
        <div className="Form__btnbox"><div className="Form__btn-return"><Link to="/" onClick={submit}>マイページTOPへ</Link></div></div>
        }
    </>
  );

}
