import { React, useState, useEffect } from "react";
import { Outlet, useLocation,Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import { Backdrop, CircularProgress } from "@mui/material";


export default function TopAuth() {
  const { data, myAuth, mailAuth } = useMyData();

  const [emailProps, resetEmail] = useInput(""); // Email(ログインID)(利用者メールアドレス)
  const [passwordProps, resetPassword] = useInput(""); // パスワード

  const [hasError, setHasError] = useState(true); // エラーチェック
  const [authStatus, setAuthStatus] = useState(""); // 認証の戻り値

  // エラーチェック
  useEffect(() => {
    let err = false;

    if(emailProps.value==="") err=true;
    if(passwordProps.value==="") err=true;

    setHasError(err);
  });


  // ログイン
  const submit = (e) => {
    e.preventDefault();
    setAuthStatus("WAITING");
    myAuth(emailProps.value, passwordProps.value,setAuthStatus);
  }

  // 認証メール再送
  const resend = (e) => {
    e.preventDefault();
    setAuthStatus("");
    mailAuth(emailProps.value);
  }


  if (data.loading) {
    return (
      <>
      <Backdrop style={{zIndex:1000,backgroundColor:'#fff'}} transitionDuration={0} open={true}>
        <CircularProgress />
      </Backdrop>
      </>
    );
  } else {
    return (
      <>
        <div className="Mypagelogin">
        <h1 className="pagetopLabel">ログイン（施設利用）</h1>

          <div className="Form__btnbox hazimete">
          <p>初めて施設利用ウェブシステムをご利用になる方は、利用者登録ボタンをクリックしてください。</p>
            <div className="Form__btn-confirm">
            <Link to="/signup">利用者登録</Link>
            </div>
          </div>
        <p>メールアドレス、パスワードを入力してください。</p>
        <form onSubmit={submit}>
          <table className="Form" summary="#">
          <tbody>
            <tr>
              <th>ID (メールアドレス)</th>
              <td><input type="text" {...emailProps} size="50" required autoComplete="email" className="w_60" /></td>
            </tr>
            <tr>
              <th>パスワード</th>
              <td><input type="password" {...passwordProps} size="50" className="w_60" autoComplete="current-password" required /></td>
            </tr>
          </tbody>
          </table>
            <div className="resetpw">
              <span className="linkArrow"><Link to="/forgot">パスワードを忘れた方はこちら（再設定）</Link></span>
              <br />
              <span className="linkArrow"><a href="https://yokohama-minatomiraihall.jp/guide/hall/concerts_input.html">公演情報の登録方法について（利用者向け）</a></span>
            </div>

          { authStatus==='WAITING' && <>認証中</> }
          { authStatus==='NO USER' && <><span className="notice">ID (メールアドレス)またはパスワードが正しくありません。</span></> }
          { authStatus==='PASSWORD ERROR' && <><span className="notice">ID (メールアドレス)またはパスワードが正しくありません。</span></> }
          { authStatus==='TOO MATCH FAILS' && <><span className="notice">ID (メールアドレス)またはパスワードが正しくありません。</span></> }
          { authStatus==='NOT MAIL AUTHED' && <><span className="notice">ご入力のIDとパスワードは、利用者登録のための認証が完了していません。<br />認証を行い、利用者登録のための申込みを完了してください。<br /> (<a onClick={resend}>認証用メールを再送する。</a>)</span></> }
          { authStatus==='NOT TOFWY' && <><span className="notice">登録はまだ完了していません。<br />登録完了まで、受付日の翌営業日から最大3営業日のお時間をいただきます。<br />登録の完了はメールにてお知らせいたします。通知メール到着まで、今しばらくお待ちください。</span></> }

          <div className="Form__btnbox">
            <div className="Form__btn-confirm">
              <input value="ログイン" type="submit" id="next" disabled={ hasError } />
            </div>
          </div>


        </form>
        </div>
      </>
    );
  }
}
