import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMyData } from './MyProvider';

export default function MailAuth() {
  let {uuid, id, mail} = useParams();

  const [sendCheck, setSendCheck] = useState(false); // 送信中
  const [retStatus, setRetStatus] = useState(""); // 送信結果


  useEffect(() => {
    mailauth(setSendCheck);
  },[]);


  async function mailauth(target) {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'mailauth',
        {
          headers: {
            'Content-type':'application/json'
          },
          method: "POST",
          body: JSON.stringify({ id, uuid, mail }),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if(data && data.status) {
        console.log(data);
        setRetStatus(data.status);
        target(true);

      }
    } catch (error) {
      console.log(error);
    }
  }



  return (
    <>

      <h1 className="pagetopLabel">利用者登録（認証確認）</h1>

      <ul className="Progressbar">
        <li><span>情報の入力</span></li>
        <li><span>入力内容の確認</span></li>
        <li className={sendCheck && retStatus!=="OK" ? "active" : ""}><span>送信・メールの確認</span></li>
        <li className={sendCheck && retStatus=="OK" ? "active" : ""}><span>申込み完了</span></li>
      </ul>

      <div className="txt">
        { ! sendCheck && <h3>確認中………</h3> }

        { sendCheck && retStatus==="OK" && <><h3>認証を確認しました。</h3><p>横浜みなとみらいホール施設利用ウェブシステム利用者登録のための【申込み】を受け付けました。<br /><br />
        利用者登録が完了しましたら、再度メールにてお知らせいたします。<br />
        登録完了通知メール到着まで、今しばらくお待ちください。</p>
        <br />
        <ul className="kome">
          <li>※登録完了まで、受付日の翌営業日から最大3営業日のお時間をいただきます。</li>
          <li>※5営業日を過ぎても登録完了のメールが届かない場合は大変恐れ入りますが、横浜みなとみらいホール運営チーム（TEL:045-682-2020）までお問合せください。</li>
        </ul>
          </> }
        { sendCheck && retStatus!=="OK" && <><h3>認証確認に失敗しました。</h3><p>「認証確認」ができませんでした。<br />アドレス(URL)の有効時間が切れているためと考えられます。<br />以下のA・B いずれかの手順で、再度メール認証を行ってください。 </p>
        <br />
        <ul className="kome">
          <li>A：IDとパスワードがわかる場合
            <ol className="num">
              <li>マイページログイン画面より入力したID・パスワードでログインを行ってください。</li>
              <li>認証メール再送のご案内が表示されますので、そちらから手続きをやり直してください。</li>
              <li>認証用メールが再送されます。確認のうえ、30分以内に認証を完了してください。</li>
            </ol>
          </li>
          <li>B：パスワードを忘れてしまった場合
          <ol className="num">
              <li>マイページログイン画面より【パスワードを忘れた方はこちら（再設定）】をクリックし、画面の案内にしたがってパスワードの再設定を行ってください。</li>
              <li>パスワード再設定完了のメールを受信確認後、Aの手順で手続きを行ってください。</li>
          </ol>
          </li>
        </ul>
        </> }
      </div>

  </>
 );
}
