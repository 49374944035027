import { React } from "react";
import { useMyData } from './MyProvider';
import SignUpGroupConfirm from "./SignUpGroupConfirm";
import SignUpGroupInput from "./SignUpGroupInput";
import SignUpGroupSend from "./SignUpGroupSend";

export default function SignUpGroup() {

  const { getSignupData } = useMyData();

  const [signupData, signupMode] = getSignupData();


  return (
    <>
      {signupMode==='confirm' && <SignUpGroupConfirm />}
      {signupMode==='send' && <SignUpGroupSend />}
      {(!signupMode || signupMode==='' || signupMode==='back') && <SignUpGroupInput />}

    </>
 );
}