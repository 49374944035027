import { Backdrop, CircularProgress } from "@mui/material";
import { React, useEffect,useState } from "react";
import { useMyData } from './MyProvider';
import ReserveAddAlert from "./ReserveAddAlert";
import ReserveAddBan from "./ReserveAddBan";
import ReserveAddConfirm from "./ReserveAddConfirm";
import ReserveAddFin from "./ReserveAddFin";
import ReserveAddTop from "./ReserveAddTop";

export default function ReserveAdd() {
  const { dataPractice,getPractice,setRoom } = useMyData();

  useEffect(() => {
    getPractice();
    setRoom(0);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);


  return (
    <>
    <Backdrop style={{zIndex:1000,backgroundColor:'#fffa'}} open={dataPractice.loading ? true : false}>
      <CircularProgress />
    </Backdrop>
    {dataPractice && dataPractice.ban===1 &&  <ReserveAddBan />}
    {dataPractice && dataPractice.ban===0 && dataPractice.alert===1 &&  <ReserveAddAlert />}
    {dataPractice && dataPractice.ban===0 && dataPractice.mode==='confirm' && <ReserveAddConfirm />}
    {dataPractice && dataPractice.ban===0 && dataPractice.mode==='send' && <ReserveAddFin mode="send" />}
    {dataPractice && dataPractice.ban===0 && dataPractice.mode==='fin' && <ReserveAddFin mode="fin" />}
    {dataPractice && dataPractice.ban===0 && dataPractice.ym && (typeof dataPractice.mode === 'undefined' || dataPractice.mode == 'back' ) && <ReserveAddTop />}
    </>
  );
}
