import { React,useState,useEffect } from "react";
import { useMyData } from './MyProvider';


export default function ReserveAddSpCalDate(props) {

  const { dataPractice,dataPracticeSpDay,setDataPracticeSpDay } = useMyData();
  const [active, setActive] = useState();
  const [select, setSelect] = useState();
  const [holiday, setHoliday] = useState(undefined);

  
  useEffect(() => {
    if(dataPracticeSpDay && dataPracticeSpDay.key==props.day.key){
      setSelect("select");
    }else{
      setSelect("");
    }
  }, [dataPracticeSpDay]);
  
  useEffect(() => {
    let active="";
    if(dataPractice.req && dataPractice.req.length > 0){

      if(props.day.date){
        let y = props.day.date.getFullYear();
        let m = props.day.date.getMonth() + 1;
        let d = props.day.date.getDate();
        if( m < 10 ){
          m = "0" + m;
        }
        if( d < 10 ){
          d = "0" + d;
        }
    
        const found = dataPractice.req[0].match(/^\d{3}-(\d{4}-\d{2}-\d{2})[abc]$/);
        if(found && found[1] === y + '-' + m + '-' + d ) {
          active="active";
        }
      }
    }
    setActive(active);
  });

  useEffect(() => {
    if(dataPractice.ym) {
      if(dataPractice[dataPractice.ym]) {
        if(props.day.date) {
          const y = props.day.date.getFullYear();
          let m = props.day.date.getMonth() + 1;
          let d = props.day.date.getDate();
          if ( m < 10 ) {
            m = "0" + m;
          }
          if ( d < 10 ) {
            d = "0" + d;
          }
          const ymd = y + '-' + m + '-' + d;
          let hld = null;
          if(dataPractice[dataPractice.ym].cal){
            if(dataPractice[dataPractice.ym].cal[ymd]){
              hld = dataPractice[dataPractice.ym].cal[ymd].holiday;
            }
          }
          setHoliday(hld);
        }
      }
    }
  }, [dataPractice.ym,props]);

  
  let dateClass = "";

  if(props.day.date){
    let y = props.day.date.getFullYear();
    let m = props.day.date.getMonth() + 1;
    let d = props.day.date.getDate();
    if( m < 10 ){
      m = "0" + m;
    }
    if( d < 10 ){
      d = "0" + d;
    }

    let rsvData = props.dataPractice[props.dataPractice.ym] ? props.dataPractice[props.dataPractice.ym].cal : [];

    if(rsvData && rsvData[y + '-' + m + '-' + d]) {
      let dayRsv101 = rsvData[y + '-' + m + '-' + d][101];
      let dayRsv102 = rsvData[y + '-' + m + '-' + d][102];
      let dayRsv103 = rsvData[y + '-' + m + '-' + d][103];
      let dayRsv104 = rsvData[y + '-' + m + '-' + d][104];
      let dayRsv105 = rsvData[y + '-' + m + '-' + d][105];
      let dayRsv106 = rsvData[y + '-' + m + '-' + d][106];

      if( dayRsv101.close===1 ||
          dayRsv102.close===1 ||
          dayRsv103.close===1 ||
          dayRsv104.close===1 ||
          dayRsv105.close===1 ||
          dayRsv106.close===1 
        ) {
        dateClass = "holiday ";
      }
    }

    
    if(props.day.date.getDay()==0){
      dateClass = dateClass + "sunday";
    }
    if(props.day.date.getDay()==6){
      dateClass = dateClass + "saturday";
    }
  }

  const selectDay = (e) => {
    setDataPracticeSpDay(props.day);
  }


  return (
    <>
      {props.day.date ? 
      <td
      className={holiday ? "sunday" : dateClass}
      ><a className={active + ' ' + select} onClick={selectDay}><p>{props.day.date.getDate()}</p></a></td>
      :
      <td className="none">&nbsp;</td>
      }
    </>
  );

}
