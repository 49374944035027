import { React } from "react";
import ReserveAddSpCalDate from "./ReserveAddSpCalDate";


export default function ReserveAddSpCalWeek(props) {

  return (
    <>
              <tr>
              {props.week.map((day) => (
                <ReserveAddSpCalDate key={day.key} day={day} dataPractice={props.dataPractice} />
              ))}
              </tr>
    </>
  );

}
