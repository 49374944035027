import { React, useState, useEffect } from "react";
import { Outlet, useParams,Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput,useRadio } from "./hooks";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { fontSize } from "@mui/system";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

const Input = styled('input')({
  display: 'none',
});

export default function ConcertPrAddSend() {
  let {id} = useParams();
  if(!id){
    id = 'new';
  }

  const { data,dataConcert,dataConcertPr,setDataConcertPr,getConcert,addConcertPr } = useMyData();

  const [sendCheck, setSendCheck] = useState(false); // 送信中

  useEffect(()=>{
    getConcert(id);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },[data]);

  useEffect(() => {
    if(dataConcertPr.mode=='send') {
      addConcertPr(id,setSendCheck);
    }
  },[]);

  useEffect(() => {
    if(sendCheck) {
      setDataConcertPr( {
        mode: "sended"
      });
    }
  },[sendCheck]);




  const convertDate = (date) => {
    const dow=['日','月','火','水','木','金','土'];
    const d = new Date(date);
    return d.getFullYear() +'年' + (d.getMonth() +1) +'月' + d.getDate() +'日' + '(' + dow[d.getDay()] + ')';
  }

  // 確認画面へ
  const submit = (e) => {
    e.preventDefault();

    if(e.target.id==='back') {
      setDataConcertPr( {
        ...dataConcertPr,
        mode: "back"
      });
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
    { dataConcert.id || id=='new' ?
      <>
        <h1 className="pagetopLabel">公演情報登録(完了)</h1>

        { ! sendCheck && <h3>登録中………</h3> }

        { sendCheck && <><h5>公演情報の登録を完了しました。</h5>
          <p>確認事項がある場合はホールスタッフよりご連絡します。</p><br />
          <h5>コンサートカレンダーへの掲載をご希望された方へ</h5>
          <p>ウェブサイトの掲載まで７営業日程度かかります。<br />
          なお、下記の場合は横浜みなとみらいホール広報担当までご連絡ください。<br />
          ・登録した情報に変更が生じたとき<br />
          ・登録から７営業日を過ぎてもウェブサイト版コンサートカレンダーへの掲載がないとき<br />
          横浜みなとみらいホール 事業企画グループ 広報チーム<br />
          電話：045-682-2020</p>
         </> }

        {true &&
        <div className="Form__btnbox">
          <div className="Form__btn-return">
            { ! sendCheck && <input value="戻る" type="submit" id="back" onClick={submit} /> }
            { sendCheck && <Link to="/concerts">戻る</Link> }
          </div>
        </div>
        }

      </>
    : '' }
    </>
 );
}
