import { React, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import SignOutDialog from "./SignOutDialog";

export default function BreadCrumbs() {
  let location = useLocation();
  const { data, signOut } = useMyData();
  const [signOutDialogOpen, setSignOutDialogOpen] = useState(false);

  const menus = [
    {
      "path": "signup",
      "name": "利用者登録",
      "child": [
        {
          "path": "group",
          "name": "団体申込み"
        },
        {
          "path": "indivisual",
          "name": "個人申込み"
        }
      ]
    },
    {
      "path": "mailauth",
      "name": "利用者登録（認証確認）"
    },
    {
      "path": "forgot",
      "name": "パスワード再設定"
    },
    {
      "path": "password",
      "name": "パスワード再設定"
    },

  ];

  const paths = location.pathname.replace(/^\//,'').split('/');

  let searchmenus = [...menus];
  let bmenus = [];
  let notfound = false;
  let parent = '/';
  paths.forEach( m => {
    let foundMenu = searchmenus.find(
      menu => menu.path === m
    );
    if(foundMenu) {
      if (foundMenu.child) {
        searchmenus = foundMenu.child;
      }
      bmenus.push( {...foundMenu, path:parent + foundMenu.path} );
      parent = parent + foundMenu.path + '/';
    } else {
      if(bmenus.length == 0) {
        if ( location.pathname !== '/' ) {
          notfound=true;
        }
      }
    }
  })


  // ログアウト
  const signout = (e) => {
    e.preventDefault();
    setSignOutDialogOpen(false);
    setSignOutDialogOpen(true);
    //signOut();
  }
  const closeDialog = (e) => {
    setSignOutDialogOpen(false);
  }


  return (
    <>
    { (data && data.token) ?
    <div className="l-pankuzu">
      <ul className="username">
        <li>前回ログイン日時： {data.lastlogin}　利用者名：{data.name} <span className="logout">(<a onClick={signout}>ログアウト</a>)</span></li>
      </ul>
      <SignOutDialog open={signOutDialogOpen} onClose={closeDialog} />
    </div>
    :
    <div className="l-pankuzu">
      <ul>
        <li><a href="/">トップ</a></li>
        { bmenus.map( (bmenu, i) => ( <li key={i}><Link to={bmenu.path}>{bmenu.name}</Link></li> ) ) }
        { notfound && data.token && <li>404 Not Found</li> }
      </ul>
    </div>
    }
    </>
  );
}
