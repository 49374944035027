import { React, useState, useEffect } from "react";
import { useMyData } from './MyProvider';
import TopAuth from "./TopAuth";
import TopMain from "./TopMain";



export default function Top() {
  const { data } = useMyData();

  return (
    <>
      { data.token ? <TopMain /> : <TopAuth /> }
    </>
 );
}