import { React, useEffect, useState } from "react";
import { useMyData } from './MyProvider';
import ReserveAddPcCalDate from "./ReserveAddPcCalDate";
import ReserveAddPcCalDay from "./ReserveAddPcCalDay";


export default function ReserveAddPcCalWeek(props) {
  const { dataPractice } = useMyData();
  const [holidays, setHolidays] = useState({});

  useEffect(() => {

    if(dataPractice.ym) {
      if(dataPractice[dataPractice.ym]) {
        if(props.week) {
          let hlds = {};
          props.week.forEach((e) => {
            if ( e.date ) {
              const y = e.date.getFullYear();
              let m = e.date.getMonth() + 1;
              let d = e.date.getDate();
              if ( m < 10 ) {
                m = "0" + m;
              }
              if ( d < 10 ) {
                d = "0" + d;
              }
              const ymd = y + '-' + m + '-' + d;
              let hld = null;
              if(dataPractice[dataPractice.ym].cal){
                if(dataPractice[dataPractice.ym].cal[ymd]){
                  hld = dataPractice[dataPractice.ym].cal[ymd].holiday;
                }
              }
              hlds[e.key] = hld;
            }
            
          });
          setHolidays(hlds);
        }

      }
    }
  }, [dataPractice.ym,props]);


  return (
    <>
        <table className="CalendarRoom">
          <thead>
            <tr>
              <th rowSpan="2"></th>
              {props.week.map((day) => (
                <ReserveAddPcCalDate key={day.key} day={day} holiday={holidays[day.key]} dataPractice={props.dataPractice} />
              ))}
            </tr>
            <tr>
              <th>午前</th>
              <th>午後</th>
              <th>夜間</th>
              <th>午前</th>
              <th>午後</th>
              <th>夜間</th>
              <th>午前</th>
              <th>午後</th>
              <th>夜間</th>
              <th>午前</th>
              <th>午後</th>
              <th>夜間</th>
              <th>午前</th>
              <th>午後</th>
              <th>夜間</th>
              <th>午前</th>
              <th>午後</th>
              <th>夜間</th>
              <th>午前</th>
              <th>午後</th>
              <th>夜間</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>練習室1</td>
              {props.week.map((day) => (
                <ReserveAddPcCalDay key={day.key} day={day} holiday={holidays[day.key]} room={101} dataPractice={props.dataPractice} enable={props.enable} />
              ))}
            </tr>
            <tr>
              <td>練習室2</td>
              {props.week.map((day) => (
                <ReserveAddPcCalDay key={day.key} day={day} holiday={holidays[day.key]} room={102} dataPractice={props.dataPractice} enable={props.enable} />
              ))}
            </tr>
            <tr>
              <td>練習室3</td>
              {props.week.map((day) => (
                <ReserveAddPcCalDay key={day.key} day={day} holiday={holidays[day.key]} room={103} dataPractice={props.dataPractice} enable={props.enable} />
              ))}
            </tr>
            <tr>
              <td>練習室4</td>
              {props.week.map((day) => (
                <ReserveAddPcCalDay key={day.key} day={day} holiday={holidays[day.key]} room={104} dataPractice={props.dataPractice} enable={props.enable} />
              ))}
            </tr>
            <tr>
              <td>練習室5</td>
              {props.week.map((day) => (
                <ReserveAddPcCalDay key={day.key} day={day} holiday={holidays[day.key]} room={105} dataPractice={props.dataPractice} enable={props.enable} />
              ))}
            </tr>
            <tr>
              <td>練習室6</td>
              {props.week.map((day) => (
                <ReserveAddPcCalDay key={day.key} day={day} holiday={holidays[day.key]} room={106} dataPractice={props.dataPractice} enable={props.enable} />
              ))}
            </tr>
          </tbody>
        </table>

    </>
  );

}
